import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Pagination from '@material-ui/lab/Pagination'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import CardHeader from '../../../components/Utils/CardHeader'
import { useAuthState } from '../../../hooks/context'
import { getUserSubscriptions } from '../../../hooks/actions/UserSubscriptionsAction'
import LoaderContainer from '../../../components/LoaderContainer'
import { getCourseDate } from '../../../helpers'
import './style.scss'

const UserCoursesList = () => {
  const { t } = useTranslation(['common', 'messages'])

  const { courses, totalPages, totalItems, errorMessage, loading } = useSelector(
    (state) => state.UserSubscriptionsReducer
  )
  const dispatch = useDispatch()

  const userDetails = useAuthState()
  const [successMessage] = useState('')
  const initialFilterState = {
    isCommunicationCourse: false,
    isDraft: false,
    sort: 'order[id]=DESC',
    page: null
  }
  const [filter, setFilter] = useState(initialFilterState)
  const [checkbox, setCheckbox] = React.useState({
    isCommunicationCourse: false,
    isDraft: false
  })

  useEffect(() => {
    getUserSubscriptions(dispatch, { filter })
  }, [dispatch, userDetails, filter])

  const handleChange = (evt) => {
    const value = {
      ...filter,
      [evt.target.name]: evt.target.value
    }
    setFilter(value)
  }
  const toggleChecked = (evt) => {
    setCheckbox({ ...checkbox, [evt.target.name]: evt.target.checked })
    setFilter({
      ...filter,
      [evt.target.name]: evt.target.checked
    })
  }

  const showFilter = () => {
    return (
      <Box display="flex" className="flex-col-mobile mt-1" alignItems="baseline">
        <Box
          className="container-filter flex-col-mobile"
          display="flex"
          flexGrow={1}
          align="center"
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  name="isCommunicationCourse"
                  checked={checkbox.isCommunicationCourse}
                  onChange={toggleChecked}
                />
              }
              label="Communication course"
            />
          </FormGroup>
        </Box>
        <Box alignItems="flex-end" display="flex" align="end">
          <FormControl variant="outlined" margin="dense" className="form-control">
            <InputLabel htmlFor="sort">{t('Sort')}</InputLabel>
            <Select
              native
              value={filter.sort}
              label={t('Sort')}
              onChange={handleChange}
              inputProps={{
                name: 'sort',
                id: 'sort'
              }}
            >
              <option aria-label="None" value="">
                {t('Sort')}
              </option>
              <option value="order[id]=ASC">{t('Oldest')}</option>
              <option value="order[id]=DESC">{t('Newest')}</option>
            </Select>
          </FormControl>
        </Box>
      </Box>
    )
  }

  const handleChangePagination = (event, value) => {
    setFilter({
      ...filter,
      page: value
    })
  }

  const showPagination = () => {
    return (
      <div>
        <Pagination
          count={totalPages}
          color="secondary"
          onChange={handleChangePagination}
        />
      </div>
    )
  }

  return (
    <Container className="page-trainer-courses" maxWidth={false}>
      {/* Courses List */}
      <CardHeader title={t('Filter')}>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            {showFilter()}
          </Grid>
        </Grid>
      </CardHeader>
      {successMessage ? (
        <div className="full-width mt-2">
          <Alert severity="success">{successMessage}</Alert>
        </div>
      ) : null}
      {errorMessage ? (
        <div className="full-width mt-2">
          <Alert severity="error">{errorMessage}</Alert>
        </div>
      ) : null}
      {/* List */}
      <CardHeader title={t('Courses list')} count={totalItems}>
        <List>
          <LoaderContainer loading={loading}>
            {courses && courses.length === 0 ? (
              'No courses found'
            ) : (
              <>
                {courses &&
                  courses.map((courseDetails, key) => {
                    const { course } = courseDetails
                    return (
                      <>
                        <ListItem className="course-item" key={key}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Box display="flex" flexdirection="row" alignItems="center">
                                <div
                                  className={`course-visual ${
                                    course.isCommunicationCourse
                                      ? 'communication icon-students'
                                      : 'icon-student-card'
                                  }`}
                                ></div>
                                <ListItemText
                                  primary={course.name}
                                  secondary={
                                    <React.Fragment>
                                      <Typography
                                        component="span"
                                        variant="body2"
                                        color="textPrimary"
                                      >
                                        <Grid
                                          container
                                          className="mt-1"
                                          direction="row"
                                          alignItems="center"
                                        >
                                          <EventAvailableIcon className="secondary mr-1" />{' '}
                                          {getCourseDate(course)}
                                        </Grid>
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </ListItem>
                        {key + 1 !== courses.length && <Divider />}
                      </>
                    )
                  })}
              </>
            )}
          </LoaderContainer>
        </List>
      </CardHeader>
      <Box width={1} mt={5}>
        {showPagination()}
      </Box>
    </Container>
  )
}

export default UserCoursesList
