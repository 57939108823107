import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import Alert from '@material-ui/lab/Alert'
import { useParams } from 'react-router-dom'
import {
  Box,
  Popover,
  TextField,
  Button,
  CircularProgress,
  Grid,
  Typography,
  Grow
} from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers'
import {
  setSession,
  editSession,
  deleteSession,
  getSessions,
  initErrors
} from '../../../hooks/actions/SessionActions'
import Editor from '../../../components/Forms/Editor'
import CoursePreviewButton from '../../../components/CoursePreviewButton'
import SaveIcon from '@material-ui/icons/Save'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import DateRangeIcon from '@material-ui/icons/DateRange'
import AddIcon from '@material-ui/icons/Add'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { initialTime } from '../../../helpers'
import './style.scss'

const CreateCourseSessions = () => {
  const { t } = useTranslation(['common', 'messages'])
  const dispatch = useDispatch()
  const { id } = useParams()
  const { loading, errorMessage, sessions } = useSelector((state) => state.SessionReducer)
  const initialSessionState = {
    id: null,
    name: '',
    description: '',
    startsAt: null,
    endsAt: null,
    course: `/api/courses/${id}`
  }
  const [state, setValues] = useState(initialSessionState)
  const [activeSession, setActiveSession] = useState(null)

  useEffect(() => {
    initErrors(dispatch)
    getSessions(dispatch, { courseId: id })
  }, [dispatch, id])

  useEffect(() => {
    scrollTop()
  }, [errorMessage])

  useEffect(() => {
    clearState()
    // eslint-disable-next-line
  }, [sessions])

  const scrollTop = () => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    } catch (error) {
      window.scrollTo(0, 0)
    }
  }

  const handleChange = (evt) => {
    setValues((prevProps) => ({
      ...prevProps,
      [evt.target.name]: evt.target.valueAsNumber || evt.target.value
    }))
  }

  const handleChangeEditor = (data, name) => {
    setValues((prevProps) => ({
      ...prevProps,
      [name]: data
    }))
  }

  const handleDateChange = (date, name) => {
    setValues((prevProps) => ({
      ...prevProps,
      [name]: date
    }))
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    const response = state.id
      ? await editSession(dispatch, { ...state })
      : await setSession(dispatch, { ...state })
    if (response && response.id) {
      clearState()
    }
  }

  const clearState = () => {
    setValues(initialSessionState)
    setActiveSession(null)
  }

  const handleEditSession = (session) => () => {
    scrollTop()
    setValues(session)
    setActiveSession(session.id)
  }

  const handleDeleteSession = (id, popupState) => () => {
    deleteSession(dispatch, id)
    popupState.close()
  }

  const handlePopoverSession = (session) => {
    return (
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <div>
            <Button size="small" color="secondary" {...bindTrigger(popupState)}>
              <DeleteOutlineIcon />
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            >
              <Box p={2}>
                <Typography>{t('messages:Are you sure to continue?')}</Typography>
                <Box align="center">
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={handleDeleteSession(session.id, popupState)}
                  >
                    {t('Delete')}
                  </Button>
                </Box>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    )
  }

  const getListSessions = () => {
    return sessions && sessions.length === 0 ? (
      <Box
        className="no-data gray"
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={1}
        py={2}
        mt={2}
        bgcolor="grey.100"
      >
        <Box align="center">
          <span className="icon-searching"></span>
          <Typography className="gray">
            {t("messages:You haven't added any sessions yet")}
          </Typography>
        </Box>
      </Box>
    ) : (
      <div>
        <Button
          size="large"
          variant="contained"
          className="btn-gray text-left back-btn"
          href={`/trainer/course/${id}`}
          fullWidth
        >
          <ImportContactsIcon className="secondary mr-1" /> {t('Back to Course Details')}
        </Button>
        <Box display="flex" alignItems="center" mt={3} mb={2}>
          <Typography variant="subtitle2" fontSize={18}>
            <strong>{t('Total Sessions')}</strong>
          </Typography>
          <Box ml={'auto'}>
            <Typography variant="subtitle2" className="secondary">
              <strong>{sessions?.length}</strong>
            </Typography>
          </Box>
        </Box>
        {sessions &&
          sessions.map((session, key) => (
            <Grow
              in={true}
              style={{ transformOrigin: '0 0 0' }}
              key={key}
              timeout={1000 + key * 400}
            >
              <div
                className={`session-card container-gray p-1 ${
                  activeSession === session.id ? ' active' : ''
                }`}
                onClick={handleEditSession(session)}
              >
                <div>
                  <Box display="flex" className="mt-0" p={0} py={0}>
                    <Box flexGrow={1}>
                      <Typography className="mb-0 session-title" gutterBottom>
                        <Grid container direction="row" alignItems="center">
                          <DescriptionOutlinedIcon className="secondary mr-1" />
                          {`Session#${key + 1}:`}
                        </Grid>
                      </Typography>
                      <Typography variant="subtitle2" className="secondary session-name">
                        <strong>{session.name}</strong>
                      </Typography>
                      <Typography variant="body2" className="mb-0 -bold" gutterBottom>
                        <Grid container direction="row" alignItems="center">
                          <DateRangeIcon fontSize="small" className="secondary mr-1" />
                          {moment(session.startsAt).format('YYYY-MM-DD')}
                          <Typography
                            variant="body2"
                            component="span"
                            className="-bold secondary session-time"
                          >
                            {`( ${moment(session.startsAt).format('HH:mm')} to ${moment(
                              session.endsAt
                            ).format('HH:mm')} )`}
                          </Typography>
                        </Grid>
                      </Typography>
                    </Box>
                    <Box align="center" alignItems="flex-end">
                      {handlePopoverSession(session)}
                    </Box>
                  </Box>
                </div>
              </div>
            </Grow>
          ))}
      </div>
    )
  }

  const showSessionForm = () => {
    return (
      <form autoComplete="off" onSubmit={handleSubmit} className="admin-form">
        <div className="">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box display="flex" className="input-btn">
                <TextField
                  required
                  className="form-input"
                  name="name"
                  label={t('Name')}
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={state.name || ''}
                />
                <Button variant="contained" color="secondary" onClick={clearState}>
                  <AddIcon className="ml-1" />
                  {t('Add a session')}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="" component="div" gutterBottom>
                {t('Description')}
              </Typography>
              <Editor
                required
                name="description"
                data={state.description || ''}
                onChange={handleChangeEditor}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="div" gutterBottom>
                {t('Session Time')}
              </Typography>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <KeyboardDateTimePicker
                      className="date-input outlined secondary"
                      fullWidth
                      ampm={false}
                      label={t('Time Start')}
                      value={state.startsAt}
                      format="DD/MM/yyyy HH:mm"
                      onChange={(date) => handleDateChange(date, 'startsAt')}
                      initialFocusedDate={initialTime(8, 0)}
                      minutesStep={15}
                      inputVariant="outlined"
                      autoOk
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <KeyboardDateTimePicker
                      className="date-input outlined secondary"
                      fullWidth
                      ampm={false}
                      variant="inline"
                      label={t('Time End')}
                      value={
                        state.endsAt ||
                        (state.startsAt
                          ? moment(state.startsAt).add(2, 'hours')
                          : state.startsAt)
                      }
                      format="DD/MM/yyyy HH:mm"
                      initialFocusedDate={initialTime(8, 0)}
                      onChange={(date) => handleDateChange(date, 'endsAt')}
                      minutesStep={15}
                      inputVariant="outlined"
                      autoOk
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </div>
        <div>
          {state.id && (
            <Box mb={1}>
              <Button
                onClick={clearState}
                size="large"
                variant="contained"
                disabled={loading}
                fullWidth
              >
                {t('Close')}
              </Button>
            </Box>
          )}
          <Button
            className="btn-gray"
            type="submit"
            size="large"
            variant="contained"
            disabled={loading}
            fullWidth
          >
            <SaveIcon className="secondary mr-1" />
            {t('Save')}
            {loading && <CircularProgress size={24} className="ml-1" color="secondary" />}
          </Button>
        </div>
      </form>
    )
  }

  return (
    <div className="page-sessions">
      <Grid container spacing={4}>
        <Grid item className="session-form" md={7} xs={12}>
          {errorMessage ? (
            <div className="full-width mb-2">
              <Alert severity="error">{errorMessage}</Alert>
            </div>
          ) : null}
          {showSessionForm()}
        </Grid>
        <Grid item md={5} xs={12} className="session-container">
          {getListSessions()}
          <Grid item xs={12} className="mb-0 session-actions">
            <div className="mt-1">
              <CoursePreviewButton />
            </div>
            <div className="mt-1">
              <Button
                size="large"
                variant="contained"
                color="secondary"
                disabled={loading}
                href={`/trainer/courses`}
                fullWidth
              >
                {t('Done')} <DoneAllIcon className="ml-1" />
                {loading && (
                  <CircularProgress size={24} className="ml-1" color="secondary" />
                )}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default CreateCourseSessions
