import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import Alert from '@material-ui/lab/Alert'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import walletImg from '../../../assets/images/wallet.svg'
import SelectCurrencies from '../../../components/Forms/SelectCurrencies'
import TablePagination from '../../../components/TablePagination/TablePagination'
import MuiDialog from '../../../components/Utils/MuiDialog'
import { dateTimeFormat } from '../../../config'
import { getUser } from '../../../hooks/actions/UserActions'
import {
  creditWallet,
  getTransactionsHistory,
  updateCreditWallet
} from '../../../hooks/actions/WalletActions'
import { apiMediaUrl } from '../../../hooks/constants/api'
import { useAuthDispatch, useAuthState } from '../../../hooks/context'
import './style.scss'

const Wallet = ({ location }) => {
  const { t } = useTranslation(['messages', 'common'])
  const query = new URLSearchParams(location.search)
  const orderId = query.get('orderId')
  const { user } = useAuthState()
  const dispatchUser = useAuthDispatch()
  let history = useHistory()
  const { transactionsHistory, totalPages, loadingCredit, paymentStatus, errorMessage } =
    useSelector((state) => state.WalletReducer)
  const walletDispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [wallet, setWallet] = useState({
    amount: 0
  })

  useEffect(() => {
    getTransactionsHistory(walletDispatch, { page })
  }, [page, walletDispatch])

  useEffect(() => {
    getUser(dispatchUser)
  }, [dispatchUser])

  useEffect(() => {
    if (paymentStatus?.ErrorCode === '0' && orderId) {
      getUser(dispatchUser)
      getTransactionsHistory(walletDispatch)
    }
  }, [paymentStatus, orderId, walletDispatch, dispatchUser])

  useEffect(() => {
    if (orderId) {
      updateCreditWallet(walletDispatch, { orderId: orderId })
    }
  }, [orderId, walletDispatch])

  function createData(id, ref, date, amount, status, publicPath) {
    return { id, ref, date, amount, status, publicPath }
  }

  function getRows() {
    let rows = []
    if (transactionsHistory?.length > 0) {
      transactionsHistory.map((transaction) =>
        rows.push(
          createData(
            transaction.id,
            transaction.reference,
            transaction.transaction?.createdAt
              ? moment(transaction.transaction?.createdAt).format(dateTimeFormat)
              : '',
            transaction.transaction?.amount,
            transaction.transaction?.status,
            transaction.transaction?.publicPath
          )
        )
      )
    }
    return rows
  }

  const handleChangeWallet = (evt) => {
    const value = {
      ...wallet,
      [evt.target.name]: evt.target.valueAsNumber || evt.target.value
    }
    setWallet(value)
  }

  const getFinalUserCredit = () => {
    return Number(user.creditPoints) + Number(wallet.amount)
  }

  const creditMyWallet = () => {
    creditWallet(walletDispatch, wallet)
  }

  const handleModalClose = () => {
    history.push('/wallet')
  }

  const getPaymentMessage = () => {
    if (orderId) {
      return (
        <MuiDialog
          open={true}
          setOpen={handleModalClose}
          title={t('Payment status')}
          content={
            <Box>
              {paymentStatus ? (
                <Alert
                  severity={`${paymentStatus?.ErrorCode === '0' ? 'success' : 'error'}`}
                >{`${
                  paymentStatus?.ErrorCode === '0'
                    ? t('messages:Payment Successful')
                    : t('messages:Payment Failed')
                }`}</Alert>
              ) : (
                <div className="txt-center">
                  <CircularProgress size={24} className="ml-1" color="secondary" />
                </div>
              )}
            </Box>
          }
          actions={
            <>
              <Button onClick={() => handleModalClose(false)} color="primary">
                {t('Close')}
              </Button>
            </>
          }
        />
      )
    }
  }

  return (
    <div className="wallet-container">
      <div className="txt-center">
        <Typography variant="h4" component="h2" className="txt-center">
          {t('Your current balance')}
        </Typography>
        {getPaymentMessage()}
        <div className="inner-content">
          <Typography variant="h4" component="h2" color="secondary">
            {user.creditPoints} Pts
          </Typography>
          <div className="img-container">
            <img src={walletImg} alt="React Logo" />
          </div>
          {errorMessage && (
            <Box mt={2} p={2}>
              <Alert severity="error">{errorMessage}</Alert>
            </Box>
          )}
          <Box mt={2} p={2}>
            <Grid container spacing={1} justifyContent="center">
              <Grid container xs={12} md={6} spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">
                      {t('Amount')}
                    </InputLabel>
                    <OutlinedInput
                      id="amount"
                      name="amount"
                      type="number"
                      value={wallet?.amount}
                      placeholder={'0'}
                      onChange={handleChangeWallet}
                      startAdornment={
                        <InputAdornment className="secondary" position="start">
                          $
                        </InputAdornment>
                      }
                      labelWidth={60}
                      inputProps={{
                        min: 0
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectCurrencies
                    disabled
                    currency={user?.currency?.id || 0}
                    label={t('Currency')}
                    defaultLabel={t('Select currency')}
                    name="currency"
                  />
                </Grid>
              </Grid>
              <Grid xs={12} spacing={2} justifyContent="center">
                <Box mt={4}>
                  <Typography variant="h6" component="h2" className="txt-center">
                    {t('Final balance')}
                  </Typography>
                  <Typography variant="h4" component="h2" color="secondary">
                    {getFinalUserCredit()} Pts
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <div className="txt-center mt-2">
            <Button
              className="button"
              variant="contained"
              color="secondary"
              size="large"
              onClick={creditMyWallet}
              disabled={loadingCredit}
            >
              {t('Charge my wallet')}
              {loadingCredit && (
                <CircularProgress size={24} className="ml-1" color="secondary" />
              )}
            </Button>
          </div>
        </div>
      </div>
      <Box mt={5} mb={2}>
        <Typography variant="h5" component="h4" color="secondary">
          {t('Transaction history')}
        </Typography>
      </Box>
      <TableContainer component={Paper} style={{ maxHeight: 400 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t('Reference')}</TableCell>
              <TableCell align="right">{t('Date')}</TableCell>
              <TableCell align="right">{t('Amount')}</TableCell>
              <TableCell align="right">{t('Status')}</TableCell>
              <TableCell align="right">{t('Invoice')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getRows().map((row) => (
              <TableRow key={row.id} style={{ height: '65px' }}>
                <TableCell component="th" scope="row">
                  {row.ref}
                </TableCell>
                <TableCell align="right">{row.date}</TableCell>
                <TableCell align="right">{row.amount}</TableCell>
                <TableCell align="right">{t(row.status)}</TableCell>
                <TableCell align="right">
                  {row.publicPath && (
                    <Link
                      to={{ pathname: `${apiMediaUrl}${row.publicPath}` }}
                      target="_blank"
                    >
                      <PictureAsPdfIcon />
                    </Link>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box width={1} mt={4}>
        <TablePagination
          page={page}
          totalPages={totalPages}
          setPage={(page) => setPage(page)}
        />
      </Box>
    </div>
  )
}
export default Wallet
