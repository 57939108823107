import moment from 'moment'
import { commentsPerPage, coursesPerPage } from '../../config/config'
import { publicApi } from '../../services/api'
import {
  categoriesUriPath,
  courseCommentsPath,
  courseRatingsPath,
  coursesPath,
  coursesUriPath,
  languagesUriPath,
  levelsUriPath,
  userPath
} from '../constants/api'
import { courseConstants } from '../constants/courseConstants'
import { getContentType } from '../helpers/header'

export async function setCourse(dispatch, coursePayload) {
  coursePayload = setUriPaths(coursePayload)
  try {
    dispatch({ type: courseConstants.REQUEST_COURSE_CREATE })
    let response = await publicApi.post(coursesPath, JSON.stringify(coursePayload))
    let data = await response.data
    if (data.id) {
      dispatch({ type: courseConstants.CREATE_COURSE_SUCCESS, payload: data })
      return data
    }
    dispatch({ type: courseConstants.CREATE_COURSE_ERROR, error: data.message })
    return
  } catch (error) {
    dispatch({ type: courseConstants.CREATE_COURSE_ERROR, error: error })
  }
}

export async function cloneCourse(dispatch, coursePayload) {
  try {
    dispatch({ type: courseConstants.REQUEST_COURSE_CLONE })
    let response = await publicApi.post(
      `${coursesPath}/${coursePayload.id}/clone`,
      JSON.stringify(coursePayload)
    )
    let data = await response.data
    if (data.id) {
      dispatch({ type: courseConstants.CLONE_COURSE_SUCCESS, payload: data })
      return data
    }
    dispatch({ type: courseConstants.CLONE_COURSE_ERROR, error: data.message })
    return
  } catch (error) {
    dispatch({ type: courseConstants.CLONE_COURSE_ERROR, error: error })
  }
}

export async function editCourse(dispatch, coursePayload) {
  coursePayload = setUriPaths(coursePayload)
  try {
    dispatch({ type: courseConstants.REQUEST_COURSE_EDIT })
    let response = await publicApi.put(
      `${coursesPath}/${coursePayload.id}`,
      JSON.stringify(coursePayload)
    )
    let data = await response.data
    if (data.id) {
      dispatch({ type: courseConstants.EDIT_COURSE_SUCCESS, payload: data })
      return data
    }
    dispatch({ type: courseConstants.EDIT_COURSE_ERROR, error: data.message })
    return
  } catch (error) {
    dispatch({ type: courseConstants.EDIT_COURSE_ERROR, error: error })
  }
}

function setUriPaths(payload) {
  if (payload.language?.id) {
    payload.language = `${languagesUriPath}/${payload.language.id}`
  } else payload.language = null

  if (payload.level?.id) {
    payload.level = `${levelsUriPath}/${payload.level.id}`
  } else payload.level = null

  if (payload.category?.id) {
    payload.category = `${categoriesUriPath}/${payload.category.id}`
  } else payload.category = null

  return payload
}

export async function publishCourse(dispatch, courseId) {
  try {
    dispatch({ type: courseConstants.REQUEST_COURSE_PUBLISH, id: courseId })
    let response = await publicApi.post(
      `${coursesPath}/${courseId}/publish`,
      JSON.stringify([])
    )
    let data = await response.data
    if (data.id) {
      dispatch({ type: courseConstants.PUBLISH_COURSE_SUCCESS, payload: data })
      return data
    }
    dispatch({ type: courseConstants.PUBLISH_COURSE_ERROR, error: data.message })
    return
  } catch (error) {
    dispatch({ type: courseConstants.PUBLISH_COURSE_ERROR, error: error })
  }
}

export async function unpublishCourse(dispatch, courseId) {
  try {
    dispatch({ type: courseConstants.REQUEST_COURSE_UNPUBLISH, id: courseId })
    let response = await publicApi.post(
      `${coursesPath}/${courseId}/unpublish`,
      JSON.stringify([])
    )
    let data = await response.data
    if (data.id) {
      dispatch({ type: courseConstants.UNPUBLISH_COURSE_SUCCESS, payload: data })
      return data
    }
    dispatch({ type: courseConstants.UNPUBLISH_COURSE_ERROR, error: data.message })
    return
  } catch (error) {
    dispatch({ type: courseConstants.UNPUBLISH_COURSE_ERROR, error: error })
  }
}

export async function deleteCourse(dispatch, courseId) {
  try {
    dispatch({ type: courseConstants.REQUEST_COURSE_DELETE })
    let response = await publicApi.delete(`${coursesPath}/${courseId}`)
    if (response.status === 204) {
      dispatch({ type: courseConstants.DELETE_COURSE_SUCCESS, payload: { id: courseId } })
    }
    return
  } catch (error) {
    dispatch({ type: courseConstants.DELETE_COURSE_ERROR, error: error })
  }
}

export async function rateCourse(dispatch, coursePayload) {
  if (coursePayload.id) {
    coursePayload.course = `${coursesPath}/${coursePayload.id}`
  }
  try {
    dispatch({ type: courseConstants.REQUEST_COURSE_RATING, id: coursePayload.id })
    let response = await publicApi.post(courseRatingsPath, JSON.stringify(coursePayload))
    if (response.status === 403) {
      dispatch({
        type: courseConstants.RATING_COURSE_ERROR,
        error: response.data.message
      })
    }
    let data = await response.data
    if (data.id) {
      dispatch({
        type: courseConstants.RATING_COURSE_SUCCESS,
        payload: {
          id: data.id,
          value: data.value
        }
      })
      return data
    }

    dispatch({ type: courseConstants.RATING_COURSE_ERROR, error: data.message })
    return
  } catch (error) {
    dispatch({ type: courseConstants.RATING_COURSE_ERROR, error: error })
  }
}

export async function editRateCourse(dispatch, coursePayload) {
  try {
    dispatch({ type: courseConstants.REQUEST_COURSE_EDIT_RATING, id: coursePayload.id })
    let response = await publicApi.patch(
      `${courseRatingsPath}/${coursePayload.ratingId}`,
      coursePayload,
      {
        headers: { ...getContentType('patch') }
      }
    )
    let data = await response.data
    if (data.id) {
      dispatch({
        type: courseConstants.EDIT_RATING_COURSE_SUCCESS,
        payload: {
          id: data.id,
          value: data.value
        }
      })
      return data
    }
    dispatch({ type: courseConstants.EDIT_RATING_COURSE_ERROR, error: data.message })
    return
  } catch (error) {
    dispatch({ type: courseConstants.EDIT_RATING_COURSE_ERROR, error: error })
  }
}

export async function commentCourse(dispatch, coursePayload) {
  if (coursePayload.id) {
    coursePayload.course = `${coursesUriPath}/${coursePayload.id}`
    delete coursePayload.id
  }
  try {
    dispatch({ type: courseConstants.REQUEST_COURSE_COMMENT, id: coursePayload.id })
    let response = await publicApi.post(courseCommentsPath, JSON.stringify(coursePayload))
    let data = await response.data
    if (data.id) {
      dispatch({
        type: courseConstants.COMMENT_COURSE_SUCCESS,
        payload: {
          id: data.id,
          value: data.comment
        }
      })
      return data
    }
    dispatch({ type: courseConstants.COMMENT_COURSE_ERROR, error: data.message })
    return
  } catch (error) {
    dispatch({ type: courseConstants.COMMENT_COURSE_ERROR, error: error })
  }
}

export async function getCourseComments(dispatch, coursePayload) {
  try {
    dispatch({ type: courseConstants.REQUEST_COURSE_COMMENTS })
    let response = await publicApi.get(
      `${coursesPath}/${coursePayload.courseId}/comments?perPage=${commentsPerPage}&user[notin]=${coursePayload.userId}&order[id]=DESC&page=${coursePayload.page}`
    )
    let data = await response.data
    if (data) {
      dispatch({
        type: courseConstants.GET_COURSE_COMMENTS_SUCCESS,
        payload: {
          data: data['hydra:member'],
          totalPages:
            data && data['hydra:totalItems']
              ? Math.ceil(data['hydra:totalItems'] / commentsPerPage)
              : 0,
          totalItems: data['hydra:totalItems']
        }
      })
      return
    }
    dispatch({
      type: courseConstants.GET_COURSE_COMMENTS_ERROR,
      error: data['hydra:description']
    })
    return
  } catch (error) {
    dispatch({ type: courseConstants.GET_COURSE_COMMENTS_ERROR, error: error })
  }
}

export async function getCourse(dispatch, coursePayload) {
  try {
    dispatch({ type: courseConstants.REQUEST_COURSE })
    let response = await publicApi.get(`${coursesPath}/${coursePayload.courseId}`)
    let data = await response.data
    if (data) {
      dispatch({ type: courseConstants.GET_COURSE_SUCCESS, payload: data })
      return
    }

    dispatch({ type: courseConstants.GET_COURSE_ERROR, error: data['hydra:description'] })
    return
  } catch (error) {
    dispatch({ type: courseConstants.GET_COURSE_ERROR, error: error })
  }
}

export async function getCourses(dispatch, coursePayload) {
  const filterURI = getFilterParams(coursePayload.filter)
  try {
    dispatch({ type: courseConstants.REQUEST_COURSES_LIST })
    let response = await publicApi.get(
      `${coursesPath}?perPage=${coursesPerPage}${filterURI}`
    )
    let data = await response.data
    if (data) {
      dispatch({
        type: courseConstants.GET_COURSES_SUCCESS,
        payload: {
          data: data['hydra:member'],
          totalPages:
            data && data['hydra:totalItems']
              ? Math.ceil(data['hydra:totalItems'] / coursesPerPage)
              : 0,
          totalItems: data['hydra:totalItems']
        }
      })
      return data
    }
    dispatch({
      type: courseConstants.GET_COURSES_ERROR,
      error: data['hydra:description']
    })
    return
  } catch (error) {
    dispatch({ type: courseConstants.GET_COURSES_ERROR, error: error })
  }
}

export async function getTrainerCourses(dispatch, coursePayload) {
  const filterURI = getFilterParams(coursePayload.filter)
  try {
    dispatch({ type: courseConstants.REQUEST_TRAINER_COURSES_LIST })
    let response = await publicApi.get(
      `${userPath}/${coursePayload.userId}/courses?perPage=${coursesPerPage}${filterURI}`
    )
    let data = await response.data
    if (data) {
      dispatch({
        type: courseConstants.GET_TRAINER_COURSES_SUCCESS,
        payload: {
          data: data['hydra:member'],
          totalPages:
            data && data['hydra:totalItems']
              ? Math.ceil(data['hydra:totalItems'] / coursesPerPage)
              : 0,
          totalItems: data['hydra:totalItems']
        }
      })
      return data
    }
    dispatch({
      type: courseConstants.GET_TRAINER_COURSES_ERROR,
      error: data['hydra:description']
    })
    return
  } catch (error) {
    dispatch({ type: courseConstants.GET_TRAINER_COURSES_ERROR, error: error })
  }
}

export async function getTrainerUpcomingCourses(dispatch, coursePayload) {
  try {
    dispatch({ type: courseConstants.REQUEST_TRAINER_UPCOMING_COURSES })
    let response = await publicApi.get(
      `${userPath}/${coursePayload.userId}/courses?upcoming=1`
    )
    let data = await response.data
    if (data) {
      dispatch({
        type: courseConstants.GET_TRAINER_UPCOMING_COURSES_SUCCESS,
        payload: {
          data: data['hydra:member']
        }
      })
      return data
    }
    dispatch({
      type: courseConstants.GET_TRAINER_UPCOMING_COURSES_ERROR,
      error: data['hydra:description']
    })
    return
  } catch (error) {
    dispatch({ type: courseConstants.GET_TRAINER_UPCOMING_COURSES_ERROR, error: error })
  }
}

export async function getTrainerPastCourses(dispatch, coursePayload) {
  try {
    dispatch({ type: courseConstants.REQUEST_TRAINER_PAST_COURSES })
    let response = await publicApi.get(
      `${userPath}/${coursePayload.userId}/courses?upcoming=0`
    )
    let data = await response.data
    if (data) {
      dispatch({
        type: courseConstants.GET_TRAINER_PAST_COURSES_SUCCESS,
        payload: {
          data: data['hydra:member']
        }
      })
      return data
    }
    dispatch({
      type: courseConstants.GET_TRAINER_PAST_COURSES_ERROR,
      error: data['hydra:description']
    })
    return
  } catch (error) {
    dispatch({ type: courseConstants.GET_TRAINER_PAST_COURSES_ERROR, error: error })
  }
}

export async function getTrainerReviews(dispatch, userPayload = null) {
  try {
    dispatch({ type: courseConstants.REQUEST_TRAINER_REVIEWS_GET })
    let response = await publicApi.get(`/${userPayload.userId}/reviews`)
    let data = await response.data
    if (data) {
      dispatch({
        type: courseConstants.GET_TRAINER_REVIEWS_SUCCESS,
        payload: {
          data: data['hydra:member']
        }
      })
      return data
    }
    dispatch({ type: courseConstants.GET_TRAINER_REVIEWS_ERROR, error: data })
    return
  } catch (error) {
    dispatch({ type: courseConstants.GET_TRAINER_REVIEWS_ERROR, error: error })
  }
}

const getFilterParams = (filter) => {
  let filterURI = ''
  if (filter?.isCommunicationCourse) {
    filterURI += `&isCommunicationCourse=${filter.isCommunicationCourse}`
  }
  if (filter?.isDraft) {
    filterURI += `&isDraft=${filter.isDraft}`
  }
  if (filter?.isArchived) {
    filterURI += `&isArchived=${filter.isArchived}`
  }
  if (filter?.price) {
    filterURI += `&price[between]=${filter.price[0]}..${filter.price[1]}`
  }
  if (filter?.startsAt) {
    filterURI += `&startsAt[after]=${moment(filter.startsAt).format('YYYY-MM-DD')}`
  }
  if (filter?.endsAt) {
    filterURI += `&endsAt[before]=${moment(filter.endsAt).format('YYYY-MM-DD')}`
  }
  if (filter?.level && filter.level.length > 0) {
    filter.level.map((level) => (filterURI += `&level[]=${level}`))
  }
  if (filter?.language && filter.language.length > 0) {
    filter.language.map((lang) => (filterURI += `&language[]=${lang}`))
  }
  if (filter?.category && filter.category.length > 0) {
    filter.category.map((cat) => (filterURI += `&category[]=${cat}`))
  }
  if (filter?.name) {
    filterURI += `&name=${filter.name}`
  }
  if (filter?.sort) {
    filterURI += `&${filter.sort}`
  }
  if (filter?.page) {
    filterURI += `&page=${filter.page}`
  }
  return filterURI
}

export async function handleFormChange(dispatch, coursePayload) {
  dispatch({ type: courseConstants.HANDLE_FORM_CHANGE, payload: coursePayload })
}

export async function handleSessionsFormChange(dispatch, sessionPayload) {
  dispatch({ type: courseConstants.HANDLE_SESSIONS_FORM_CHANGE, payload: sessionPayload })
}

export async function initErrors(dispatch) {
  dispatch({ type: courseConstants.INIT_ERRORS })
}
export async function initRatingErrors(dispatch) {
  dispatch({ type: courseConstants.INIT_RATING_ERRORS })
}
