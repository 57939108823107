import { checkoutsConstants } from '../constants/checkoutsConstants'
import { checkoutsPath } from '../constants/api'
import { publicApi } from '../../services/api'
import { getPageParams } from '../../helpers/helpers'
import { transactionsPerPage } from '../../config/config'

export async function getCheckouts(dispatch, userPayload = null) {
  let URIParams = ''
  if (userPayload && (userPayload.filter || userPayload.page)) {
    URIParams = getPageParams(userPayload, transactionsPerPage)
  }
  try {
    dispatch({ type: checkoutsConstants.REQUEST_CHECKOUTS_GET })
    let response = await publicApi.get(`${checkoutsPath}${URIParams}`)
    let data = await response.data
    if (data) {
      dispatch({
        type: checkoutsConstants.GET_CHECKOUTS_SUCCESS,
        payload: {
          data: data['hydra:member'],
          totalPages:
            data && data['hydra:totalItems']
              ? Math.ceil(data['hydra:totalItems'] / transactionsPerPage)
              : 0
        }
      })
      return data
    }
    dispatch({ type: checkoutsConstants.GET_CHECKOUTS_ERROR, error: data })
    return
  } catch (error) {
    dispatch({ type: checkoutsConstants.GET_CHECKOUTS_ERROR, error: error })
  }
}
export async function initErrors(dispatch) {
  dispatch({ type: checkoutsConstants.INIT_ERRORS })
}
