import { combineReducers } from 'redux'
import HomeReducer from './HomeReducer'
import TrainerCoursesReducer from './TrainerCoursesReducer'
import UserSubscriptionsReducer from './UserSubscriptionsReducer'
import CourseReducer from './CourseReducer'
import WalletReducer from './WalletReducer'
import CurrenciesReducer from './CurrenciesReducer'
import ThemeReducer from './ThemeReducer'
import SessionReducer from './SessionReducer'
import ActiveSessionReducer from './ActiveSessionReducer'
import CourseReportReducer from './CourseReportReducer'
import MessagesReducer from './MessagesReducer'
import DashboardReducer from './DashboardReducer'
import CheckoutsReducer from './CheckoutsReducer'

const rootReducer = combineReducers({
  HomeReducer,
  TrainerCoursesReducer,
  CourseReducer,
  UserSubscriptionsReducer,
  WalletReducer,
  CurrenciesReducer,
  ThemeReducer,
  SessionReducer,
  ActiveSessionReducer,
  CourseReportReducer,
  MessagesReducer,
  DashboardReducer,
  CheckoutsReducer
})

export default rootReducer
