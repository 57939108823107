import { Container, Grid } from '@material-ui/core'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { MediaProvider } from '../../hooks/context/MediaContext'
import { SubscriptionProvider } from '../../hooks/context/SubscriptionContext'
import ArchivedCourses from './ArchivedCourses/ArchivedCourses'
import CreateCourse from './CreateCourse'
import CreateCourseSessions from './CreateCourseSessions'
import TrainerCourses from './TrainerCourses'

const TrainerCoursesRoutes = () => {
  return (
    <Container maxWidth={false}>
      <div>
        <Grid container>
          <Grid item xs={12} className="">
            <SubscriptionProvider>
              <MediaProvider>
                <Switch>
                  <Route path="/trainer/courses" exact component={TrainerCourses} />
                  <Route
                    path="/trainer/archived-courses"
                    exact
                    component={ArchivedCourses}
                  />
                  <Route path="/trainer/course/create" exact component={CreateCourse} />
                  <Route path="/trainer/course/:id" exact component={CreateCourse} />
                  <Route
                    path="/trainer/course/:id/sessions"
                    exact
                    component={CreateCourseSessions}
                  />
                </Switch>
              </MediaProvider>
            </SubscriptionProvider>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

export default TrainerCoursesRoutes
