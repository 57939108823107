import React, { useState } from 'react';
import moment from "moment";
import { Box, Grid, Typography, Accordion, AccordionDetails, AccordionSummary, Divider } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { showSessionDuration } from '../../helpers';
import AnimatedList from '../Utils/AnimatedList';
import './style.scss';

const CourseSessions = ({ sessions, isAdmin }) => {

  const [expanded, setExpanded] = useState(false);

  const handleChangePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="course-sessions">
      {sessions && sessions.map((session, key) => (
        <AnimatedList animated={!isAdmin} key={key} duration={+key}>
          <Accordion className="accordion-container" expanded={expanded === `panel-${key}`} onChange={handleChangePanel(`panel-${key}`)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              flexdirection="column"
              className="accordion-column--flex tab-header"
            >
              <Box display="flex" className="full-width mt-0 item__header" flexDirection="column" p={0} py={0}>
                <Box flexGrow={1}>
                  <Typography className="mb-0" gutterBottom>
                    <Grid container direction="row" alignItems="center">
                      <DescriptionOutlinedIcon className="secondary mr-1"/>
                      {session.name}
                    </Grid>
                  </Typography>
                  <Typography variant="body2" className="mb-0" gutterBottom>
                    <Grid container direction="row" alignItems="center">
                      <ScheduleIcon fontSize="small" className="secondary mr-1"/>
                      {moment(session.startsAt).format('YYYY-MM-DD HH:mm')} to {moment(session.endsAt).format('HH:mm')}
                    </Grid>
                  </Typography>
                </Box>
                <Box alignItems="flex-end">
                  <Typography className="secondary-bg white text-btn mt-1" variant="button" gutterBottom>
                    {showSessionDuration(session.startsAt, session.endsAt)}
                  </Typography>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="textSecondary" component="div"><div dangerouslySetInnerHTML={{__html: session.description}}></div></Typography>
            </AccordionDetails>
            <Divider />
          </Accordion>
        </AnimatedList>
      ))}
    </div>
  );
};

export default CourseSessions;
