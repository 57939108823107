import { courseConstants } from '../constants/courseConstants'
import { authConstants } from '../constants/authConstants'

export const initialState = {
  course: '',
  courses: null,
  loading: false,
  loadingReview: false,
  loadingCourse: false,
  loadingClone: false,
  success: '',
  successRating: false,
  successComment: false,
  successClone: false,
  errorMessage: null,
  errorComment: null,
  totalPages: 0,
  totalItems: 0,
  courseEditing: false,
  trainerReviews: []
}

export const CourseReducer = (state = initialState, action) => {
  switch (action.type) {
    case courseConstants.REQUEST_TRAINER_REVIEWS_GET:
      return {
        ...state,
        loadingReview: true
      }
    case courseConstants.REQUEST_COURSE_EDIT_RATING:
    case courseConstants.REQUEST_COURSE_RATING:
      return {
        ...state,
        successRating: false
      }
    case courseConstants.REQUEST_COURSE_DELETE:
    case courseConstants.REQUEST_COURSE_COMMENT:
      return {
        ...state,
        successComment: false
      }
    case courseConstants.REQUEST_COURSE:
    case courseConstants.REQUEST_COURSE_EDIT:
    case courseConstants.REQUEST_COURSES_LIST:
    case courseConstants.REQUEST_TRAINER_COURSES_LIST:
    case courseConstants.REQUEST_COURSE_CREATE:
      return {
        ...state,
        errorMessage: null,
        loading: true
      }
    case courseConstants.REQUEST_COURSE_CLONE:
      return {
        ...state,
        loadingClone: true
      }
    case courseConstants.REQUEST_COURSE_PUBLISH:
    case courseConstants.REQUEST_COURSE_UNPUBLISH:
      return {
        ...state,
        loadingCourse: action.id,
        courseEditing: true
      }
    case courseConstants.DELETE_COURSE_SUCCESS:
      let courses = state.courses.filter((c) => c.id !== action.payload.id)
      return {
        ...state,
        courses
      }
    case courseConstants.GET_COURSE_SUCCESS:
    case courseConstants.EDIT_COURSE_SUCCESS:
    case courseConstants.CREATE_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        course: {
          ...action.payload,
          sessions: action.payload.sessions ? Object.values(action.payload.sessions) : []
        },
        errorMessage: null
      }
    case courseConstants.CLONE_COURSE_SUCCESS:
      return {
        ...state,
        loadingClone: false,
        courses: [action.payload, ...state.courses],
        errorMessage: null,
        successClone: true
      }
    case courseConstants.HANDLE_FORM_CHANGE:
      return {
        ...state,
        course: {
          ...state.course,
          ...action.payload
        }
      }
    case courseConstants.HANDLE_SESSIONS_FORM_CHANGE:
      let sess = state.course.sessions?.find((s) => s.id === action.payload.id)
      if (sess) Object.assign(sess, action.payload)
      state.loading = false
      state.success = true
      return {
        ...state
      }
    case courseConstants.RATING_COURSE_SUCCESS:
    case courseConstants.EDIT_RATING_COURSE_SUCCESS:
      return {
        ...state,
        course: {
          ...state.course,
          myRating: {
            ...state.course.myRating,
            rating: action.payload
          }
        },
        successRating: true,
        loadingReview: false
      }
    case courseConstants.COMMENT_COURSE_SUCCESS:
      return {
        ...state,
        course: {
          ...state.course,
          myRating: {
            ...state.course.myRating,
            comment: { ...state.course.myRating.comment, ...action.payload }
          }
        },
        successComment: true,
        loadingReview: false
      }
    case courseConstants.RATING_COURSE_ERROR:
    case courseConstants.COMMENT_COURSE_ERROR:
      return {
        ...state,
        errorComment: action.error,
        loadingReview: false
      }
    case courseConstants.PUBLISH_COURSE_SUCCESS:
    case courseConstants.UNPUBLISH_COURSE_SUCCESS:
      let course = state.courses.find((c) => c.id === action.payload.id)
      if (course) Object.assign(course, action.payload)
      state.loadingCourse = ''
      state.success = true
      state.courseEditing = false
      return {
        ...state
      }
    case courseConstants.GET_COURSES_SUCCESS:
    case courseConstants.GET_TRAINER_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: action.payload.data,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
        errorMessage: null
      }
    case courseConstants.GET_TRAINER_REVIEWS_SUCCESS:
      return {
        ...state,
        trainerReviews: action.payload.data,
        loadingReview: false
      }
    case courseConstants.GET_TRAINER_REVIEWS_ERROR:
    case courseConstants.DELETE_COURSE_ERROR:
    case courseConstants.GET_COURSE_ERROR:
    case courseConstants.PUBLISH_COURSE_ERROR:
    case courseConstants.UNPUBLISH_COURSE_ERROR:
    case courseConstants.EDIT_RATING_COURSE_ERROR:
    case courseConstants.EDIT_COURSE_ERROR:
    case courseConstants.GET_COURSES_ERROR:
    case courseConstants.GET_TRAINER_COURSES_ERROR:
    case courseConstants.CREATE_COURSE_ERROR:
    case courseConstants.CLONE_COURSE_ERROR:
      return {
        ...state,
        loading: false,
        loadingCourse: false,
        loadingClone: false,
        errorMessage: action.error
      }
    case courseConstants.INIT_ERRORS:
      return {
        ...state,
        errorMessage: null,
        successRating: false,
        successClone: false
      }
    case courseConstants.INIT_RATING_ERRORS:
      return {
        ...state,
        errorMessage: null,
        successRating: false,
        errorComment: null,
        loadingReview: false
      }
    case authConstants.LOGOUT:
      return initialState
    default:
      console.warn(`unhandled action CourseReducer ${action.type}`)
      return state
  }
}

export default CourseReducer
