import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import { Typography, Box } from '@material-ui/core'
import MuiDialog from '../../../components/Utils/MuiDialog'
import { getSessions, getUpcomingSessions } from '../../../hooks/actions/SessionActions'
import CourseSessions from '../../../components/CourseSessions/CourseSessions'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import './style.scss'

const Schedule = () => {
  const { i18n, t } = useTranslation()
  const { sessions, upcomingSessions } = useSelector((state) => state.SessionReducer)
  const { activeSessions } = useSelector((state) => state.ActiveSessionReducer)
  const [events, setEvents] = useState([])
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    getUpcomingSessions(dispatch)
  }, [dispatch])

  useEffect(() => {
    if (selectedEvent) {
      getSessions(dispatch, { courseId: selectedEvent.extendedProps?.courseId })
    }
  }, [dispatch, selectedEvent])

  const activeSessionsId =
    activeSessions && activeSessions.length ? activeSessions?.map((a) => a.session) : []

  const getEventColor = useCallback((event) => {
    if (event.room) {
      return '#f27b5c'
    } else if (event.isCommunicationCourse) {
      return 'rgb(217, 37, 80)'
    } else {
      return '#252525'
    }
  }, [])

  useEffect(() => {
    if (upcomingSessions) {
      const formattedEvents = []
      upcomingSessions.forEach((session) => {
        formattedEvents.push({
          title: session.name,
          start: session.startsAt,
          end: session.endsAt,
          courseId: session.courseId,
          sessionId: session.id,
          room: session.room ?? '',
          link: session.link ?? '',
          isCommunicationCourse: session.isCommunicationCourse,
          backgroundColor: getEventColor(session),
          borderColor: getEventColor(session),
          display: 'block',
          url: session.link ? session.link : `/course/${session.courseId}`
        })
        setEvents(formattedEvents)
      })
    }
  }, [upcomingSessions, getEventColor])

  const handleEventClick = ({ event, jsEvent }) => {
    jsEvent.preventDefault()
    if (event.url) {
      window.open(event.url)
    }
    setSelectedEvent(event)
  }

  const renderSessionLink = ({ event }) => {
    return event.extendedProps?.link ? (
      <>
        {event.title}
        <Link
          className="white ml-1 -bold"
          to={{
            pathname: `${event.extendedProps.link}`
          }}
          target="_blank"
        >
          {t('Join now!')}
        </Link>
      </>
    ) : (
      event.title
    )
  }

  const handleModalClose = (open) => {
    setOpen(open)
  }

  const eventModal = () => {
    if (selectedEvent && !selectedEvent?.extendedProps.isCommunicationCourse) {
      const activeSelectedSession = sessions ? sessions.find((s) => s.id)?.id : []
      return (
        <MuiDialog
          open={open}
          setOpen={handleModalClose}
          title={selectedEvent?.title}
          extraClasses={'schedule-modal footer__secondary'}
          content={<CourseSessions sessions={sessions} />}
          actions={
            <Typography className="footer-action" variant={'h5'} component="span">
              <Box display="flex" alignItems="center">
                {sessions &&
                activeSessions &&
                activeSessionsId.includes(activeSelectedSession) ? (
                  <Link
                    className="white"
                    to={{
                      pathname: `${
                        activeSessions.find((s) => s.session === activeSelectedSession)
                          ?.link
                      }`
                    }}
                    target="_blank"
                  >
                    {t('Join now!')}
                  </Link>
                ) : (
                  <Link
                    className="white"
                    to={{ pathname: `/course/${selectedEvent?.extendedProps.courseId}` }}
                    target="_blank"
                  >
                    {t('Go to course')}
                  </Link>
                )}
                <OpenInNewIcon className="ml-1" />
              </Box>
            </Typography>
          }
        />
      )
    }
  }

  return (
    <div className="schedule-container">
      <Typography>
        <FullCalendar
          timeZone={'UTC'}
          locale={i18n.language}
          plugins={[dayGridPlugin, listPlugin]}
          themeName={'darkly'}
          initialView="dayGridMonth"
          headerToolbar={{
            right: 'dayGridMonth,listMonth',
            left: 'prev,next',
            center: 'title'
          }}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
            hour12: false
          }}
          events={events}
          eventClick={(event) => handleEventClick(event)}
          eventContent={(event) => renderSessionLink(event)}
        />
      </Typography>
      {eventModal()}
    </div>
  )
}
export default Schedule
