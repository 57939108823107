import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { throttle } from 'lodash'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import {
  AppBar,
  Button,
  IconButton,
  Drawer,
  Link,
  Box,
  Typography
} from '@material-ui/core'
import { useAuthState } from '../../../hooks/context'
import LoggedMenu from '../../../components/Layout/LoggedMenu'
import SocialMedia from '../../../components/SocialMedia'
import Logo from '../../Logo'
import CurveLine from '../../../components/Utils/CurveLine/CurveLine'
import HomeBanner from '../../../pages/Home/sections/HomeBanner'
import './style.scss'

export default function HeaderPublic({ extraClasses }) {
  const userDetails = useAuthState()
  const location = useLocation()
  const { pathname } = location
  const isHome = pathname === '/'
  const { home } = useSelector((state) => state.HomeReducer)
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
    sticky: false
  })
  const { theme } = useSelector((state) => state.ThemeReducer)
  const { mobileView, drawerOpen, sticky } = state
  const { t } = useTranslation('common')
  const headersData = [
    {
      label: t('Home'),
      href: '/'
    },
    {
      label: t('Courses'),
      href: '/courses'
    },
    {
      label: t('Trainers'),
      href: '/trainers'
    }
  ]
  const socialMedia = {
    facebook: '#',
    instagram: '#',
    twitter: '#',
    linkedin: '#'
  }

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }))
    }

    setResponsiveness()

    window.addEventListener('resize', () => setResponsiveness())
  }, [])

  const checkScrollTop = () => {
    const currentScroll = window.pageYOffset
    if (currentScroll > 200) {
      setState((prevState) => ({ ...prevState, sticky: true }))
    } else {
      setState((prevState) => ({ ...prevState, sticky: false }))
    }
  }

  const throttledFunc = throttle(checkScrollTop, 100, { leading: true })
  useEffect(() => {
    window.addEventListener('scroll', throttledFunc, false)
    return () => {
      window.removeEventListener('scroll', throttledFunc)
    }
    // eslint-disable-next-line
  }, [])

  const displayDesktop = () => {
    return (
      <div className="inner-header">
        <CurveLine color={'#fff'} small extraClass={'curveOne'} />
        <div className="header-front-top">
          <div className="header-front-top__content txt-center">
            <Typography color="textSecondary">
              <SocialMedia medias={socialMedia} extraClasses="textSecondary" />
            </Typography>
            {siteLogo}
            <div className="user-menu-container">
              {userDetails && <LoggedMenu user={userDetails.user} />}
            </div>
          </div>
        </div>
        <div className="header-front-nav">{getMenuButtons()}</div>
        <div className="header-front-bottom"></div>
      </div>
    )
  }

  const displaySticky = () => {
    return (
      <div className="sticky-nav">
        {siteLogo}
        <div className="ml-auto d-flex main-menu-items">
          {getMenuButtons()}
          {userDetails && <LoggedMenu user={userDetails.user} />}
        </div>
      </div>
    )
  }

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }))
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }))

    return (
      <div className="inner-header">
        <CurveLine color={'#fff'} small />
        <div className="logo-mobile-container">
          {siteLogo}
          <div className="user-menu-container">
            {userDetails && <LoggedMenu user={userDetails.user} />}
          </div>
        </div>
        <div>
          <Drawer
            data-theme={theme}
            className="mobile-drawer"
            {...{
              anchor: 'left',
              disableDiscovery: true,
              open: drawerOpen,
              onClose: handleDrawerClose
            }}
          >
            <Box
              display="flex"
              className="drawer-header"
              justifyContent="center"
              alignItems="center"
            >
              {siteLogo}
            </Box>
            <IconButton className="menu-close-btn" onClick={handleDrawerClose}>
              <CloseIcon className="icon" />
            </IconButton>
            <div className="drawer-container">
              <Box
                className="mt-0"
                justifyContent="center"
                alignItems="center"
                p={0}
                py={0}
              >
                {getDrawerChoices(handleDrawerClose)}
              </Box>
              <Box>
                <Typography color="textSecondary">
                  <SocialMedia medias={socialMedia} extraClasses="textSecondary" />
                </Typography>
              </Box>
            </div>
          </Drawer>
          <IconButton
            {...{
              edge: 'end',
              'aria-label': 'menu',
              'aria-haspopup': 'true',
              onClick: handleDrawerOpen
            }}
          >
            <MenuIcon />
          </IconButton>
        </div>
      </div>
    )
  }

  const getDrawerChoices = (handleDrawerClose) => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          className="textSecondary"
          {...{
            component: RouterLink,
            to: href,
            style: { textDecoration: 'none' },
            key: label,
            onClick: handleDrawerClose
          }}
        >
          <Typography className="menu-item">{label}</Typography>
        </Link>
      )
    })
  }

  const siteLogo = <Logo color={'white'} />

  const getMenuButtons = () => {
    return headersData.map(({ label, href, is_logged }) => {
      return (is_logged && userDetails) || !is_logged ? (
        <Button
          {...{
            key: label,
            color: 'textSecondary',
            to: href,
            component: RouterLink,
            className: 'menu-button'
          }}
        >
          {label}
        </Button>
      ) : (
        ''
      )
    })
  }

  return (
    <>
      {sticky && displaySticky()}
      <AppBar className={`header-front ${extraClasses}`} position="relative">
        <CurveLine />
        {mobileView ? displayMobile() : displayDesktop()}
        {isHome && <HomeBanner categories={home?.CATEGORIES} />}
      </AppBar>
    </>
  )
}
