import { userSubscriptionsConstants } from "../constants/userSubscriptionsConstants";
import { userSubscriptionsPath }  from "../constants/api";
import { coursesPerPage } from "../../config/config";
import { publicApi } from "../../services/api";

export async function getUserSubscriptions(dispatch, coursePayload) {
  const filterURI = getFilterParams(coursePayload.filter);
  try {
    dispatch({ type: userSubscriptionsConstants.REQUEST_USER_SUBSCRIPTIONS });
    let response = await publicApi.get(`${userSubscriptionsPath}?perPage=${coursesPerPage}${filterURI}`);
    let data = await response.data;
    if (data ) {
      dispatch({ type: userSubscriptionsConstants.GET_USER_SUBSCRIPTIONS_SUCCESS,
        payload: {
          data : data['hydra:member'],
          totalPages:
            data && data["hydra:totalItems"]
          ? Math.ceil(data["hydra:totalItems"] / coursesPerPage)
          : 0,
          totalItems: data["hydra:totalItems"]
        }
      });
      return data
    }

    dispatch({ type: userSubscriptionsConstants.GET_USER_SUBSCRIPTIONS_ERROR, error: data['hydra:description']});
    return;
  } catch (error) {
    dispatch({ type: userSubscriptionsConstants.GET_USER_SUBSCRIPTIONS_ERROR, error: error });
  }
}

const getFilterParams = (filter) => {
  let filterURI = "";
  if (filter?.isCommunicationCourse) {
    filterURI += `&isCommunicationCourse=${filter.isCommunicationCourse}`;
  }
  if (filter?.isDraft) {
    filterURI += `&isDraft=${filter.isDraft}`;
  }
  if (filter?.sort) {
    filterURI += `&${filter.sort}`;
  }
  if (filter?.page) {
    filterURI += `&page=${filter.page}`;
  }
  return filterURI;
}

export async function initErrors(dispatch) {
  dispatch({ type: userSubscriptionsConstants.INIT_ERRORS });
}
