import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import './style.scss';

const LoaderContainer = ({ children, loading }) => {
  return (
    loading ? (<Skeleton variant="rect" height={118} />) : children
  );
};

export default LoaderContainer;
