import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Typography,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  TextField,
  InputAdornment
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import SearchIcon from '@material-ui/icons/Search'
import SortFilter from '../../../components/Filters/SortFilter'
import ItemFilter from '../../../components/Filters/ItemFilter'
import SelectCountries from '../../../components/Forms/SelectCountries'
import TrainerCard from '../../../components/TrainerCard'
import { getUsers } from '../../../hooks/actions/UserActions'
import { useUsersDispatch, useUsersState } from '../../../hooks/context/UsersContext'
import LoaderContainer from '../../../components/LoaderContainer'
import HeaderFilter from '../../../components/Filters/HeaderFilter/HeaderFilter'
import './style.scss'

const Trainers = () => {
  const { t } = useTranslation('common')
  const dispatchTrainers = useUsersDispatch()
  const { users, totalPages, loading } = useUsersState()
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState('')
  const initialFilterState = {
    country: '',
    name: ''
  }
  const [filter, setFilter] = useState(initialFilterState)
  const [activeFilter, setActiveFilter] = useState(false)

  useEffect(() => {
    getUsers(dispatchTrainers, { filter, page, sort })
  }, [dispatchTrainers, filter, page, sort])

  const handleChangeCountry = (value) => {
    setPage(1)
    setFilter({
      ...filter,
      ...value
    })
    setActiveFilter(true)
  }

  const handleKeyDown = (evt) => {
    if (evt.key === 'Enter') {
      setFilter({
        ...filter,
        [evt.target.name]: evt.target.value
      })
    }
  }

  const handleSort = (evt) => {
    setSort(evt.target.value)
  }

  const handleChangePagination = (event, value) => {
    setPage(value)
  }

  const clearFilter = () => {
    setFilter(initialFilterState)
    setActiveFilter(false)
    setPage(1)
  }

  const Filter = () => {
    return (
      <HeaderFilter activeFilter={activeFilter} clearFilter={clearFilter}>
        <ItemFilter title={t('Country')} AccordionParams={{ defaultExpanded: true }}>
          <SelectCountries
            defaultLabel={t('Select a country')}
            country={filter.country}
            handleChange={handleChangeCountry}
          />
        </ItemFilter>
      </HeaderFilter>
    )
  }

  const sortFilter = () => {
    return (
      <SortFilter
        searchBox={
          <TextField
            className="form-input"
            name="name"
            fullWidth
            onKeyDown={handleKeyDown}
            placeholder={t('Search')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        }
        sortSelect={
          <FormControl variant="outlined" margin="dense" className="form-control">
            <InputLabel htmlFor="sort">{t('Sort')}</InputLabel>
            <Select
              native
              value={sort}
              label={t('Sort')}
              onChange={handleSort}
              inputProps={{
                name: 'sort',
                id: 'sort'
              }}
            >
              <option value="order[id]=DESC">{t('Oldest')}</option>
              <option value="order[id]=ASC">{t('Newest')}</option>
            </Select>
          </FormControl>
        }
      />
    )
  }

  const showPagination = () => {
    return (
      <div>
        <Pagination
          count={totalPages}
          page={page}
          color="secondary"
          onChange={handleChangePagination}
        />
      </div>
    )
  }

  return (
    <Container className="page-trainers" maxWidth={false}>
      <Grid className="p-1 flex-col-mobile" container spacing={2}>
        <Grid item md={3} xs={12}>
          <Typography variant="h4" color="textSecondary" className="m-0">
            {t('Trainers')}
          </Typography>
        </Grid>
        <Grid item md={9} sm={12}>
          {sortFilter()}
        </Grid>
      </Grid>
      <Grid className="flex-col-mobile" container spacing={3}>
        <Grid item md={3} sm={12}>
          {Filter()}
        </Grid>
        <Grid item md={9} sm={12}>
          <Grid container spacing={3}>
            <LoaderContainer loading={loading}>
              {users && users.length === 0 ? (
                <Grid item>
                  <Typography>{t('No users found')}</Typography>
                </Grid>
              ) : (
                <>
                  {users &&
                    users.map((user, key) => (
                      <Grid
                        item
                        key={key}
                        lg={4}
                        md={6}
                        sm={6}
                        xs={12}
                        className="d-flex"
                      >
                        <TrainerCard user={user} fullWidth />
                      </Grid>
                    ))}
                </>
              )}
            </LoaderContainer>
          </Grid>
          <Box width={1} mt={4}>
            {showPagination()}
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Trainers
