import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import { Alert } from '@material-ui/lab'
import Pagination from '@material-ui/lab/Pagination'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import CourseCloneModal from '../../../components/CourseCloneModal/CourseCloneModal'
import CourseSkeleton from '../../../components/Skeleton/CourseSkeleton'
import CardHeader from '../../../components/Utils/CardHeader'
import { getCourseDate } from '../../../helpers'
import {
  cloneCourse,
  getTrainerCourses,
  initErrors
} from '../../../hooks/actions/CourseActions'
import { useAuthState } from '../../../hooks/context'

const ArchivedCourses = () => {
  const { t } = useTranslation(['common', 'messages'])
  const userDetails = useAuthState()
  const {
    courses,
    loading,
    loadingCourse,
    loadingClone,
    totalPages,
    totalItems,
    errorMessage,
    successClone
  } = useSelector((state) => state.CourseReducer)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [courseClone, setCourseClone] = useState(null)
  const initialFilterState = {
    isArchived: true,
    sort: 'order[id]=DESC',
    page: null
  }
  const [filter, setFilter] = useState(initialFilterState)
  const history = useHistory()

  useEffect(() => {
    initErrors(dispatch)
  }, [dispatch])

  useEffect(() => {
    getTrainerCourses(dispatch, { userId: userDetails.user.id, filter })
  }, [dispatch, userDetails, filter])

  useEffect(() => {
    if (successClone) {
      history.push('/trainer/courses')
      return () => {
        initErrors(dispatch)
      }
    }
  }, [successClone, history, dispatch])

  useEffect(() => {
    if (errorMessage) {
      window.scrollTo(0, 0)
      const timer = setTimeout(() => {
        initErrors(dispatch)
      }, 6000)
      return () => clearTimeout(timer)
    }
  }, [dispatch, errorMessage])

  const handleModalOpen = (course) => {
    setOpen(true)
    setSelectedCourse(course)
  }

  const handleModalClose = (open) => {
    setOpen(open)
    setSelectedCourse(null)
  }

  const submitCourseClone = () => {
    const newSessions = courseClone.sessions.map((item) => {
      return (({ startsAt, endsAt, name, description }) => ({
        startsAt,
        endsAt,
        name,
        description
      }))(item)
    })
    const clonePayload = {
      id: courseClone.id,
      startsAt: courseClone.startsAt,
      endsAt: courseClone.endsAt,
      sessions: newSessions
    }
    cloneCourse(dispatch, clonePayload)
  }

  const clonedCourse = (course) => {
    setCourseClone(course)
  }

  const handleChangePagination = (event, value) => {
    setFilter({
      ...filter,
      page: value
    })
  }

  const showPagination = () => {
    return (
      <div>
        <Pagination
          count={totalPages}
          color="secondary"
          onChange={handleChangePagination}
        />
      </div>
    )
  }

  return (
    <Container className="page-trainer-courses" maxWidth={false}>
      {/* Courses List */}
      {errorMessage ? (
        <div className="full-width mt-2">
          <Alert severity="error">{errorMessage}</Alert>
        </div>
      ) : null}
      {/* List */}
      <CardHeader title={t('Archived courses')} count={totalItems}>
        <List>
          {courses && courses.length === 0 ? (
            'No courses found'
          ) : (
            <>
              {courses &&
                courses.map((course, key) => (
                  <>
                    {loading ? (
                      <CourseSkeleton />
                    ) : (
                      <ListItem className="course-item" key={key}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item md={7} xs={12}>
                            <Box display="flex" flexdirection="row" alignItems="center">
                              <div
                                className={`course-visual ${
                                  course.isCommunicationCourse
                                    ? 'communication icon-students'
                                    : 'icon-student-card'
                                }`}
                              ></div>
                              <ListItemText
                                style={{ zIndex: 99 }}
                                primary={
                                  <Typography
                                    className="-bold"
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    {course.name}
                                  </Typography>
                                }
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      <Grid
                                        container
                                        className="mt-1 mb-1"
                                        direction="row"
                                        alignItems="center"
                                      >
                                        <EventAvailableIcon className="secondary mr-1" />{' '}
                                        {getCourseDate(course)}
                                      </Grid>
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </Box>
                          </Grid>
                          <Grid item md={5} xs={12} style={{ zIndex: 99 }}>
                            <Box
                              display="flex"
                              flexdirection="row"
                              justifyContent="flex-end"
                              alignItems="center"
                              align="end"
                            >
                              {loadingCourse === course.id ? (
                                <CircularProgress
                                  size={24}
                                  className="secondary ml-1 mr-1"
                                  color=""
                                />
                              ) : (
                                <ul className="list-inline-flex list-action">
                                  <li>
                                    <Typography>
                                      <Link
                                        className="item-action"
                                        onClick={() => handleModalOpen(course)}
                                      >
                                        {t('Clone')}
                                      </Link>
                                    </Typography>
                                  </li>
                                </ul>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )}
                    {key + 1 !== courses.length && <Divider />}
                  </>
                ))}
            </>
          )}
        </List>
      </CardHeader>
      <Box width={1} mt={5}>
        {showPagination()}
      </Box>
      <CourseCloneModal
        title={`${t(`Clone`)} : ${selectedCourse?.name}`}
        open={open}
        setOpen={handleModalClose}
        error={errorMessage}
        courseId={selectedCourse?.id}
        submitClone={clonedCourse}
        submitCourseClone={submitCourseClone}
        loading={loadingClone}
      />
    </Container>
  )
}

export default ArchivedCourses
