import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button} from "@material-ui/core";
import CoursePreviewAdmin from '../CoursePreviewAdmin/CoursePreviewAdmin';
import MuiDialog from '../../components/Utils/MuiDialog';

const CoursePreviewButton = () => {

  const { t } = useTranslation('common');
  const [open, setOpen] = React.useState(false);

  const handleModalOpen = () => {
    setOpen(true);
  };
  
  const handleModalClose = (open) => {
    setOpen(open);
  };

  return (
    <>
      <Button className="mb-1" size="large" variant="contained" color="secondary" onClick={handleModalOpen} fullWidth>
        {t('Preview')}
      </Button>

      <MuiDialog 
        open={open} 
        setOpen={handleModalClose}
        title={t('Preview course')}
        maxWidth={"lg"}
        content={
          <CoursePreviewAdmin type="private" doNotRefresh/>
        }
      />
    </>
  )
};

export default CoursePreviewButton;
