import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useAuthState } from '../../../hooks/context';
import './style.scss';
import LoggedMenu from '../../../components/Layout/LoggedMenu';

export default function Header({handleDrawerOpen, className}) {
  const userDetails = useAuthState() //read user details from context
 
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  const { t } = useTranslation('common');
  const headersData = [
    {
      label: t('Home'),
      href: "/",
    },
    {
      label: t('Courses'),
      href: "/courses",
    },
    {
      label: t('Trainers'),
      href: "/trainers",
    },
  ];

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);
  
  const displayDesktop = () => {
    return (
      <>
        <div>
          {getMenuButtons()}
        </div>
      </>
    );
  };

  const displayMobile = () => {
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className="drawer-container">{getDrawerChoices()}</div>
        </Drawer>
      </>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: "inherit",
            style: { textDecoration: "none" },
            key: label,
          }}
        >
          <MenuItem>{label}</MenuItem>
        </Link>
      );
    });
  };

  const getMenuButtons = () => {
    return headersData.map(({ label, href, is_logged }) => {
      return (
        (is_logged && userDetails.user) || !is_logged ? (
        <Button
          {...{
            key: label,
            color: "inherit",
            to: href,
            component: RouterLink,
            className: "menu-button",
          }}
        >
          {label}
        </Button>):''
      );
    });
  };

  return (
    <AppBar className={`header bg-primary ${className}`}>
      <Toolbar className="menu-toolbar">
        <IconButton           {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}>
          <MenuIcon />
        </IconButton>
        {mobileView ? displayMobile() : displayDesktop()}
        <div className="ml-auto">
          {userDetails.user && <LoggedMenu user={userDetails.user} />}
        </div>
      </Toolbar>
    </AppBar>
  );
}
