import { PublicRoute } from './components/PublicRoute'
import { PrivateRoute } from './components/PrivateRoute'
import LayoutMain from './components/Layout/LayoutMain'
import LayoutPrivate from './components/Layout/LayoutPrivate'
import LayoutNotLogged from './components/Layout/LayoutNotLogged'
import Home from './pages/Home'
import ProfileRoutes from './pages/Profile/ProfileRoutes'
import CoursesRoutes from './pages/Courses/CoursesRoutes'
import CourseRoutes from './pages/Course/CourseRoutes'
import CourseRequests from './pages/CourseRequests'
import TrainerCoursesRoutes from './pages/Trainer/TrainerCoursesRoutes'
import UserCoursesRoutes from './pages/User/UserCoursesRoutes'
import TrainersRoutes from './pages/Trainers/TrainersRoutes'
import TrainerRoutes from './pages/TrainerProfile/TrainerRoutes'
import WalletRoutes from './pages/Wallet/WalletRoutes'
import PaymentRoutes from './pages/Payment/PaymentRoutes'
import ScheduleRoutes from './pages/Schedule/ScheduleRoutes'
import Tos from './pages/content/Tos'
import Privacy from './pages/content/Privacy'
import { CourseRequestsProvider } from './hooks/context/CourseRequestsContext'
import { Role } from './lib/router/role'
import Messages from './pages/Messages/Messages/Messages'

export const routesList = [
  {
    path: '/',
    exact: true,
    roles: [],
    routeHandler: PrivateRoute,
    handler: Home,
    layout: LayoutMain,
    props: {}
  },
  {
    path: '/trainer',
    exact: false,
    roles: [Role.Trainer],
    routeHandler: PrivateRoute,
    handler: TrainerCoursesRoutes,
    layout: LayoutPrivate,
    props: {}
  },
  {
    path: '/user',
    exact: false,
    roles: [Role.Student],
    routeHandler: PrivateRoute,
    handler: UserCoursesRoutes,
    layout: LayoutPrivate,
    props: {}
  },
  {
    path: '/trainers',
    exact: false,
    roles: [],
    routeHandler: PrivateRoute,
    handler: TrainersRoutes,
    layout: LayoutMain,
    props: {}
  },
  {
    path: '/profile',
    exact: false,
    roles: [],
    routeHandler: PrivateRoute,
    handler: ProfileRoutes,
    layout: LayoutPrivate,
    props: {}
  },
  {
    path: '/schedule',
    exact: true,
    roles: [],
    routeHandler: PrivateRoute,
    handler: ScheduleRoutes,
    layout: LayoutPrivate,
    props: {}
  },
  {
    path: '/wallet',
    exact: false,
    roles: [],
    routeHandler: PrivateRoute,
    handler: WalletRoutes,
    layout: LayoutPrivate,
    props: {}
  },
  {
    path: '/payments-reports',
    exact: false,
    roles: [Role.Trainer],
    routeHandler: PrivateRoute,
    handler: PaymentRoutes,
    layout: LayoutPrivate,
    props: {}
  },
  {
    path: '/courses',
    exact: false,
    roles: [],
    routeHandler: PrivateRoute,
    handler: CoursesRoutes,
    layout: LayoutMain,
    props: {}
  },
  {
    path: '/course',
    exact: false,
    roles: [],
    routeHandler: PrivateRoute,
    handler: CourseRoutes,
    layout: LayoutMain,
    props: {}
  },
  {
    path: '/instructor',
    exact: false,
    roles: [],
    routeHandler: PrivateRoute,
    handler: TrainerRoutes,
    layout: LayoutMain,
    props: {}
  },
  {
    path: '/tos',
    exact: false,
    roles: [],
    routeHandler: PublicRoute,
    handler: Tos,
    layout: LayoutNotLogged,
    props: { noRedirect: true }
  },
  {
    path: '/privacy',
    exact: false,
    roles: [],
    routeHandler: PublicRoute,
    handler: Privacy,
    layout: LayoutNotLogged,
    props: { noRedirect: true }
  },
  {
    path: '/course-requests',
    exact: false,
    roles: [],
    routeHandler: PrivateRoute,
    handler: CourseRequests,
    layout: LayoutMain,
    provider: CourseRequestsProvider,
    props: {}
  },
  {
    path: '/messages',
    exact: false,
    roles: [],
    routeHandler: PrivateRoute,
    handler: Messages,
    layout: LayoutPrivate,
    props: {}
  }
]
