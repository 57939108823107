import MomentUtils from '@date-io/moment'
import {
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Pagination from '@material-ui/lab/Pagination'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CourseCard from '../../../components/CourseCard'
import HeaderFilter from '../../../components/Filters/HeaderFilter/HeaderFilter'
import ItemFilter from '../../../components/Filters/ItemFilter'
import SearchResult from '../../../components/Utils/SearchResult/SearchResult'
import { getSelectedLabels } from '../../../helpers'
import { getCourses } from '../../../hooks/actions/CourseActions'
import { useAppSettingsState } from '../../../hooks/context/AppSettingsContext'
import './style.scss'

const MAX_PRICE_RANGE = 1000

const Courses = ({ location, history }) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const { courses, totalPages, loading, totalItems } = useSelector(
    (state) => state.CourseReducer
  )
  const [priceRange, setPriceRange] = useState([0, MAX_PRICE_RANGE])
  const { levels, languages, categories } = useAppSettingsState()
  const query = new URLSearchParams(location.search)
  const initialFilterState = {
    name: query.get('name') || '',
    isCommunicationCourse: [],
    price: [0, MAX_PRICE_RANGE],
    startsAt: null,
    endsAt: null,
    level: [],
    language: [],
    category: query.get('category') ? [+query.get('category')] : [],
    sort: 'order[id]=DESC',
    page: 1
  }
  const [activeFilter, setActiveFilter] = useState(false)

  const marks = [
    {
      value: 0,
      label: '0'
    },
    {
      value: MAX_PRICE_RANGE,
      label: `${MAX_PRICE_RANGE}`
    }
  ]

  const [filter, setFilter] = useState(initialFilterState)

  useEffect(() => {
    getCourses(dispatch, { filter })
  }, [dispatch, filter])

  useEffect(() => {
    setFilter(initialFilterState)
    // eslint-disable-next-line
  }, [location.search])

  useEffect(() => {
    if (filter.category.length) {
      setActiveFilter(true)
    }
  }, [filter.category.length])

  const handleChange = (evt) => {
    const value = {
      ...filter,
      page: 1,
      [evt.target.name]: evt.target.valueAsNumber || evt.target.value
    }
    setFilter(value)
    setActiveFilter(true)
  }

  const handleChangeCheckbox = (evt) => {
    let filterValues = filter[evt.target.name]
    const value = {
      ...filter,
      page: 1,
      [evt.target.name]: evt.target.checked
        ? [...filterValues, +evt.target.value]
        : filterValues.filter((val) => val !== +evt.target.value)
    }
    setFilter(value)
    setActiveFilter(true)
  }

  const handleKeyDown = (evt) => {
    if (evt.key === 'Enter') {
      setFilter({
        ...filter,
        [evt.target.name]: evt.target.value
      })
    }
  }

  const handleDateChange = (date, name) => {
    setFilter({
      ...filter,
      page: 1,
      [name]: date
    })
    setActiveFilter(true)
  }

  const handleChangePrice = (event, newValue) => {
    setFilter({ ...filter, page: 1, price: newValue })
    setActiveFilter(true)
  }

  const handleChangePriceValue = (event, newValue) => {
    setPriceRange(newValue)
  }

  function sliderText(value) {
    return `${value}`
  }

  const clearFilter = () => {
    setFilter({ ...initialFilterState, category: [], name: '' })
    setActiveFilter(false)
    setPriceRange(initialFilterState.price)
    history.push({
      search: ''
    })
  }

  const showFilter = () => {
    return (
      <>
        <HeaderFilter activeFilter={activeFilter} clearFilter={clearFilter}>
          <ItemFilter title={''} noAccordion AccordionParams={{ expanded: true }}>
            <FormControl className="multiselect-outlined" variant="outlined" fullWidth>
              <InputLabel id="category">{t('Category')}</InputLabel>
              <Select
                labelId="category-checkbox-label"
                id="category-checkbox"
                label={t('Category')}
                multiple
                value={filter.category}
                onChange={handleChange}
                input={<Input />}
                renderValue={(selected) => {
                  return getSelectedLabels(categories, selected, 'name')
                }}
                name="category"
              >
                {categories
                  ? categories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        <Checkbox checked={filter.category.indexOf(category.id) > -1} />
                        <ListItemText primary={category.name} />
                      </MenuItem>
                    ))
                  : ''}
              </Select>
            </FormControl>
          </ItemFilter>
          <ItemFilter
            title={t('Type of Courses')}
            noAccordion
            AccordionParams={{ expanded: true }}
          >
            <FormControl fullWidth>
              <FormGroup FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.isCommunicationCourse.indexOf(1) > -1}
                      onChange={handleChangeCheckbox}
                      name="isCommunicationCourse"
                      value={1}
                    />
                  }
                  label={t('Communication course')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.isCommunicationCourse.indexOf(0) > -1}
                      onChange={handleChangeCheckbox}
                      name="isCommunicationCourse"
                      value={0}
                    />
                  }
                  label={t('Course')}
                />
              </FormGroup>
            </FormControl>
          </ItemFilter>
          <ItemFilter title={t('Price')} noAccordion AccordionParams={{ expanded: true }}>
            <Box width={1} p={1}>
              <Slider
                name="price"
                value={priceRange}
                max={MAX_PRICE_RANGE}
                step={10}
                onChange={handleChangePriceValue}
                onChangeCommitted={handleChangePrice}
                valueLabelDisplay="auto"
                aria-labelledby="price-slider"
                getAriaValueText={sliderText}
                color="secondary"
                marks={marks}
                fullWidth
              />
            </Box>
          </ItemFilter>
          <ItemFilter title={t('Date')} noAccordion AccordionParams={{ expanded: true }}>
            <Box width={1} display="flex" spacing={2} alignItems="center">
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DatePicker
                  id="startsAt"
                  label={t('From')}
                  name="startsAt"
                  inputVariant="outlined"
                  value={filter.startsAt || null}
                  onChange={(date) => handleDateChange(date, 'startsAt')}
                  format="DD/MM/yyyy"
                  fullWidth
                  clearable
                  className="form-control-date"
                />
              </MuiPickersUtilsProvider>
              <span className="flex-row-separator"> - </span>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DatePicker
                  id="endsAt"
                  label={t('To')}
                  name="endsAt"
                  inputVariant="outlined"
                  value={filter.endsAt || null}
                  onChange={(date) => handleDateChange(date, 'endsAt')}
                  format="DD/MM/yyyy"
                  fullWidth
                  clearable
                  className="form-control-date"
                />
              </MuiPickersUtilsProvider>
            </Box>
          </ItemFilter>
          <ItemFilter title={t('Level')} noAccordion AccordionParams={{ expanded: true }}>
            <Box width={1}>
              <FormControl fullWidth>
                <FormGroup>
                  {levels
                    ? levels.map((level) => (
                        <FormControlLabel
                          key={level.id}
                          control={
                            <Checkbox
                              checked={filter.level.indexOf(level.id) > -1}
                              onChange={handleChangeCheckbox}
                              name="level"
                              value={level.id}
                            />
                          }
                          label={level.name}
                        />
                      ))
                    : ''}
                </FormGroup>
              </FormControl>
            </Box>
          </ItemFilter>
          <ItemFilter
            title={t('Languages')}
            noAccordion
            AccordionParams={{ expanded: true }}
          >
            <Box width={1}>
              <FormControl fullWidth>
                <FormGroup FormGroup>
                  {languages
                    ? languages.map((lang) => (
                        <FormControlLabel
                          key={lang.id}
                          control={
                            <Checkbox
                              checked={filter.language.indexOf(lang.id) > -1}
                              onChange={handleChangeCheckbox}
                              name="language"
                              value={lang.id}
                            />
                          }
                          label={lang.nativeName}
                        />
                      ))
                    : ''}
                </FormGroup>
              </FormControl>
            </Box>
          </ItemFilter>
        </HeaderFilter>
      </>
    )
  }

  const showSort = () => {
    return (
      <Grid container className="container-sort" spacing={4}>
        <Grid item xs={12} spacing={1}>
          <TextField
            className="form-input bg-white"
            name="name"
            fullWidth
            variant="outlined"
            onKeyDown={handleKeyDown}
            placeholder={t('Search for courses')}
            value={filter.name}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <Box display="flex" alignItems="center" mt={3}>
            {
              <SearchResult
                text={t('Showing {{totalItems}} Result(s)', {
                  totalItems: totalItems
                })}
              />
            }
            <Box ml={'auto'}>
              <FormControl
                variant="outlined"
                margin="none"
                className="form-control secondary"
              >
                <InputLabel htmlFor="sort">{t('Sort by')}</InputLabel>
                <Select
                  value={filter.sort}
                  native
                  open={true}
                  onChange={handleChange}
                  inputProps={{
                    name: 'sort',
                    id: 'sort'
                  }}
                  label={t('Sort')}
                >
                  <option value="order[rating]=DESC">{t('Most relevant')}</option>
                  <option value="order[price]=DESC">{t('Most expensive')}</option>
                  <option value="order[id]=DESC">{t('Newest')}</option>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
  }

  const handleChangePagination = (event, value) => {
    setFilter({
      ...filter,
      page: value
    })
  }

  const showPagination = () => {
    return (
      <div>
        <Pagination
          count={totalPages}
          page={filter.page}
          color="secondary"
          onChange={handleChangePagination}
        />
      </div>
    )
  }

  return (
    <Container className="page-courses" maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item lg={3} md={4} xs={12}>
          <Grid item xs={12}>
            {showFilter()}
          </Grid>
        </Grid>
        <Grid item lg={9} md={8} xs={12}>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              {showSort()}
            </Grid>
            <Grid item md={12} xs={12}>
              {courses && courses.length === 0 ? (
                <Typography>{t('No courses found')}</Typography>
              ) : (
                <>
                  {courses &&
                    courses.map((course, key) => (
                      <CourseCard key={key} data={course} loading={loading} />
                    ))}
                </>
              )}
            </Grid>
            <Box width={1}>{showPagination()}</Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Courses
