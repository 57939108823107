import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Avatar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from '@material-ui/core'
import { useAuthState } from '../../hooks/context'
import { apiMediaUrl } from '../../hooks/constants/api'
import Logo from '../Logo'
import sidebarList from './sidebarList'
import './style.scss'

function SidebarProfile({ menuIsOpen }) {
  const { t } = useTranslation('common')
  const userDetails = useAuthState()
  const user = userDetails.user
  const location = useLocation()

  return (
    <div className={`sidebar-container ${menuIsOpen ? 'open' : 'closed'}`}>
      <div className="user-heading">
        <Logo color={'white'} />
        <Avatar
          className="avatar"
          alt={user.firstname}
          src={user.avatar ? `${apiMediaUrl}${user.avatar.contentUrl}` : ''}
        />
        {menuIsOpen ? (
          <>
            <Typography variant="caption" component="span" className="mt-1">
              {t('Welcome')}
            </Typography>
            <Typography variant="h6" component="h1" className="mt-1">
              {user.firstname} {user.lastname}
            </Typography>
          </>
        ) : (
          ''
        )}
      </div>
      <div className="menu-list">
        <List>
          {sidebarList.map((item, key) => {
            return (
              <div key={key}>
                {(!item.roles?.length || item.roles.includes(user.role)) && (
                  <ListItem
                    key={key}
                    component={Link}
                    to={item.path}
                    className={`sidebar-menu__item ${
                      location.pathname === item.path ||
                      location.pathname === item.subPath
                        ? 'selected'
                        : ''
                    }`}
                  >
                    <ListItemIcon>
                      <item.icon />
                    </ListItemIcon>
                    <ListItemText primary={t(item.label)} />
                  </ListItem>
                )}
                {item.divider && <Divider />}
              </div>
            )
          })}
        </List>
      </div>
    </div>
  )
}

export default SidebarProfile
