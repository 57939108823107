import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined'
import ArchiveIcon from '@material-ui/icons/Archive'
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined'
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined'
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined'
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import { Role } from '../../lib/router/role'

const sidebarList = [
  {
    path: '/profile',
    subPath: '/profile/edit',
    label: 'Profile',
    icon: PermIdentityOutlinedIcon
  },
  {
    path: '/schedule',
    label: 'Schedule',
    icon: DateRangeOutlinedIcon
  },
  {
    path: '/trainer/courses',
    label: 'My courses',
    icon: MenuBookOutlinedIcon,
    roles: [Role.Trainer]
  },
  {
    path: '/trainer/archived-courses',
    label: 'Archived courses',
    icon: ArchiveIcon,
    roles: [Role.Trainer]
  },
  {
    path: '/user/courses',
    label: 'My courses',
    icon: MenuBookOutlinedIcon,
    roles: [Role.Student],
    divider: true
  },
  {
    path: '/messages',
    label: 'Messages',
    icon: ForumOutlinedIcon
  },
  {
    path: '/wallet',
    label: 'My wallet',
    icon: AccountBalanceWalletOutlinedIcon
  },
  {
    path: '/payments-reports',
    label: 'Billings & Earnings',
    icon: LocalAtmIcon
  },
  {
    path: '',
    label: 'Settings',
    icon: SettingsOutlinedIcon,
    roles: [Role.Admin]
  }
]

export default sidebarList
