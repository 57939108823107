import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/fr'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core'
import { AvatarGroup } from '@material-ui/lab'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt'
import { apiMediaUrl } from '../../hooks/constants/api'
import LinkIcon from '@material-ui/icons/Link'
import './style.scss'

const CourseRequestCardReading = ({ request }) => {
  const { i18n, t } = useTranslation(['messages', 'common'])
  moment.locale(i18n.language)

  const getAttachedCourses = () => {
    return request.suggestionsList.length === 0 ? (
      ''
    ) : (
      <div className="requested-courses">
        <Typography className="top-title white" variant="subtitle2" component="p">
          <LinkIcon className="mr-1 va-middle" />
          {t('Attached Course(s)')}
        </Typography>
        <Typography variant="subtitle1">
          <List>
            {request.suggestionsList.map((suggestion, key) => (
              <ListItem key={key} dense className="pb-0">
                <span className="icon-student-card mr-1"></span>
                <Link to={{ pathname: `/course/${suggestion.id}` }}>
                  <ListItemText primary={suggestion.name} />
                </Link>
              </ListItem>
            ))}
          </List>
        </Typography>
      </div>
    )
  }

  const getTaggedTrainer = () => {
    return request.taggedTrainers.length === 0 ? (
      ''
    ) : (
      <div className="card-trainers">
        <Typography variant="subtitle2" color="textSecondary" component="p">
          {t('Associated Trainer(s)')}
        </Typography>
        <AvatarGroup max={3}>
          {request.taggedTrainers.map((trainer, key) => (
            <div className="avatar-item">
              <Link
                className="full-abs"
                to={{ pathname: `/instructor/${trainer.id}` }}
              ></Link>
              <Avatar
                className="avatar"
                key={key}
                title={trainer.fullname}
                src={`${apiMediaUrl}${trainer.avatar}`}
              />
            </div>
          ))}
        </AvatarGroup>
      </div>
    )
  }

  return (
    <Card className="course-request-card">
      <CardHeader
        avatar={
          <Avatar src={request.creator && `${apiMediaUrl}${request.creator.avatar}`} />
        }
        action={
          <IconButton className={`icon-btn icon-btn-read`} aria-label="vote">
            <Typography
              className="-bold"
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {request.votesAvg}
            </Typography>
            <ThumbUpAltIcon className="icon" />
          </IconButton>
        }
        title={request.creator.fullname}
        subheader={moment(request.createdAt).fromNow()}
      />
      <CardContent className="card-details">
        <Typography
          className="card-title"
          variant="h6"
          color="textSecondary"
          component="p"
        >
          {request.name}
        </Typography>
        <Typography
          className="card-text"
          variant="body2"
          color="textSecondary"
          component="p"
        >
          {request.description}
        </Typography>
        {getAttachedCourses()}
        {getTaggedTrainer()}
      </CardContent>
    </Card>
  )
}

export default CourseRequestCardReading
