import {
  Avatar,
  Box,
  Container,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import yooscript from '../../../assets/images/yooscript.png'
import MessagesSkeleton from '../../../components/Skeleton/MessageSkeleton'
import { getMessages } from '../../../hooks/actions/MessagesAction'
import ForumIcon from '@material-ui/icons/Forum'
import './style.scss'

const Messages = () => {
  const { i18n, t } = useTranslation('common')
  moment.locale(i18n.language)
  const dispatchMessages = useDispatch()
  const [page, setPage] = useState(1)
  const { messages, loadingMessages, totalPages } = useSelector(
    (state) => state.MessagesReducer
  )

  useEffect(() => {
    getMessages(dispatchMessages, { page })
  }, [dispatchMessages, page])

  const handleChangePagination = (event, value) => {
    setPage(value)
  }

  const showPagination = () => {
    return (
      <div>
        <Pagination
          count={totalPages}
          color="secondary"
          onChange={handleChangePagination}
        />
      </div>
    )
  }

  return (
    <Container className="page-messages" maxWidth={false}>
      <List className="messages-container">
        {messages && messages.length === 0 ? (
          <Box textAlign="center">
            <ForumIcon fontSize="large" color="secondary" />
            <Typography variant="h6" color="textSecondary">
              {t('No Messages, yet.')}
            </Typography>
          </Box>
        ) : (
          <>
            {messages &&
              messages.map(({ message, sender, createdAt }, key) => (
                <>
                  {loadingMessages ? (
                    <MessagesSkeleton />
                  ) : (
                    <ListItem key={key} className="messages-item" alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar
                          alt="Remy Sharp"
                          src={sender?.avatar?.contentUrl ?? yooscript}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={sender?.fullname ?? t('Admin')}
                        secondary={
                          <Box my={1}>
                            {message}
                            <Typography
                              display="flex"
                              component="span"
                              variant="body2"
                              color="textSecondary"
                              className="messages-item__date"
                            >
                              {moment(createdAt).fromNow()}
                            </Typography>
                          </Box>
                        }
                      />
                    </ListItem>
                  )}
                  {key + 1 !== messages.length && <Divider component="li" />}
                </>
              ))}
          </>
        )}
      </List>
      {messages && messages.length > 0 && (
        <Box width={1} mt={5}>
          {showPagination()}
        </Box>
      )}
    </Container>
  )
}
export default Messages
