import React, {useState, useEffect, useRef} from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {Fab, Popover, Typography, Grid, TextField, InputLabel, Button, CircularProgress, Avatar} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextsmsIcon from '@material-ui/icons/Textsms';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import { setCourseRequest } from '../../hooks/actions/CourseRequestsActions';
import { useCourseRequestsDispatch, useCourseRequestsState } from '../../hooks/context/CourseRequestsContext';
import { getUsers } from '../../hooks/actions/UserActions';
import { useUsersDispatch, useUsersState } from '../../hooks/context/UsersContext';
import { apiMediaUrl } from '../../hooks/constants/api';
import './style.scss';

const CourseRequest = () => {
  const initialSessionState = {
    name: '',
    description: '',
    trainers: []
  }
  const [state, setValues] = useState(initialSessionState);
  const { t } = useTranslation('common');
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useCourseRequestsDispatch();
  const dispatchTrainers = useUsersDispatch();
  const { loading, errorMessage, success } = useCourseRequestsState();
  const { users } = useUsersState();
  const [successMessage, setSuccessMessage] = useState('');
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const autoC = useRef(null);

  useEffect(() => {
    getUsers(dispatchTrainers);
  }, [dispatchTrainers]);

  useEffect(() => {
    if(!errorMessage && success ) {
      setValues(initialSessionState);
      // CLEAR autocomplete by Dirty way (should find another solution T_T)
      const ele = autoC?.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
      if (ele) ele.click();
      setSuccessMessage(() => t("messages:Your course request has been sent."));
    }
    // eslint-disable-next-line
  }, [errorMessage, success, t]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSuccessMessage("");
  };

  const handleChange = (evt) => {
    setValues((prevProps) => ({
      ...prevProps,
      [evt.target.name]: evt.target.value
    }));
  }

  const handleSelectChange = (target, value ) => {
    setValues((prevProps) => ({
      ...prevProps,
      [target]: value
    }));
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setSuccessMessage("");
    setCourseRequest(dispatch, state);
  }

  return (
    <div className="course-request">
      <div className="floated-btn-container" onClick={handleClick}>
        {open ? (
          <Fab color="secondary">
            <CloseIcon />
          </Fab>
        ):(
          <Fab variant="extended" color="secondary">
            <TextsmsIcon className="mr-1"/> <Typography variant="body1">{t("Request course")}</Typography>
          </Fab>
        )}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className="course-request_popover"
      >
        <div className="popover__header secondary-bg">
          <Typography variant="h5" className="white">{t('Welcome !')}   👋</Typography>
          <Typography variant="body1" className="white">{t("messages:We'd be happy to receive your Request.")}</Typography>
        </div>
        <form autoComplete="off" onSubmit={handleSubmit}>
          {
            errorMessage ? <div className="full-width mb-2"><Alert severity="error">{errorMessage}</Alert></div>  : null
          }
          {
            successMessage && !errorMessage ? <div className="full-width mb-2"><Alert severity="success">{successMessage}</Alert></div>  : null
          }
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" component="span" color="textSecondary">
                {t("messages:Take a look at all course requests")}
                <Link to={{ pathname: "/course-requests" }}>
                  {" "}{t("here")}
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField required className="form-input" name="name" label={t('Name')} variant="outlined" fullWidth onChange={handleChange} value={state.name || ''}/>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label= {t('Description')}
                required
                multiline
                variant="outlined"
                rows={4}
                rowsMax = {10}
                onChange={handleChange}
                value={state.description || ''}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="mb-2 mt-1">{t('messages:Do you want to tag a trainer to this request?')}</InputLabel>
              <Autocomplete
                multiple
                id="tags-outlined"
                name="trainers"
                ref={autoC}
                onChange={(evt, newValue) => handleSelectChange("trainers",newValue)}
                options={users}
                defaultValue={state.trainers}
                getOptionLabel={(option) => option.fullname}
                renderOption={(option) => (
                  <React.Fragment>
                    <Avatar className="avatar-small mr-1" alt={option.firstname} src={option.avatar ? `${apiMediaUrl}${option.avatar.contentUrl}` : ''}/>
                    {option.fullname}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="trainers"
                    variant="outlined"
                    label={t('Trainers')}
                    helperText={t('Max 3 trainers')}
                  />
                )}
                filterSelectedOptions
                getOptionDisabled={(options) => (state.trainers.length >= 3 ? true : false)}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="mt-1">
                <Button
                  type="submit" 
                  size="large" 
                  variant="contained" 
                  color="secondary"
                  endIcon={loading ? <CircularProgress size={24} className="ml-1" color="secondary"/> : <SendIcon />}
                  fullWidth
                  disabled={loading}
                > 
                  {t("Send")}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Popover>
    </div>
  );
};

export default CourseRequest;
