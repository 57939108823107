import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import StatBox from '../../../components/StatBox/StatBox'
import TablePagination from '../../../components/TablePagination/TablePagination'
import CardHeader from '../../../components/Utils/CardHeader'
import { dateTimeFormat } from '../../../config/config'
import { getCourseDate } from '../../../helpers'
import { getCheckouts } from '../../../hooks/actions/CheckoutsActions'
import { getDashboardStats } from '../../../hooks/actions/DashboardActions'
import { apiMediaUrl } from '../../../hooks/constants/api'

const Payment = () => {
  const { t } = useTranslation(['messages', 'common'])
  const dispatchDashboard = useDispatch()
  const dispatchCheckouts = useDispatch()
  const [page, setPage] = useState(1)
  const { stats } = useSelector((state) => state.DashboardReducer)
  const { checkouts, totalPages } = useSelector((state) => state.CheckoutsReducer)

  useEffect(() => {
    getDashboardStats(dispatchDashboard)
  }, [dispatchDashboard])

  useEffect(() => {
    getCheckouts(dispatchCheckouts, { page })
  }, [page, dispatchCheckouts])

  function getRows() {
    let rows = []
    if (checkouts?.length > 0) {
      checkouts.map((checkout) =>
        rows.push(
          createData(
            checkout.id,
            checkout.transferReference || '-',
            checkout.checkoutAt
              ? moment(checkout.checkoutAt).format(dateTimeFormat)
              : '-',
            checkout.status,
            checkout.courses,
            checkout.amount,
            checkout.publicPath
          )
        )
      )
    }
    return rows
  }

  function createData(id, ref, checkoutAt, status, courses, amount, publicPath) {
    return { id, ref, checkoutAt, status, courses, amount, publicPath }
  }

  function Row(props) {
    const { row } = props
    const [open, setOpen] = useState(false)

    return (
      <React.Fragment>
        <TableRow onClick={() => setOpen(!open)} style={{ height: '65px' }}>
          <TableCell>
            {row.courses?.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.ref}
          </TableCell>
          <TableCell align="right">{row.checkoutAt}</TableCell>
          <TableCell align="right">{row.amount}</TableCell>
          <TableCell align="right">{row.status}</TableCell>
          <TableCell align="right">
            {row.publicPath && (
              <Link to={{ pathname: `${apiMediaUrl}${row.publicPath}` }} target="_blank">
                <PictureAsPdfIcon />
              </Link>
            )}
          </TableCell>
        </TableRow>
        {row.courses?.length > 0 && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                  <Table size="small" className="collapse-table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '60%' }}>{t('Course')}</TableCell>
                        <TableCell style={{ width: '20%' }}>{t('Date')}</TableCell>
                        <TableCell style={{ width: '10%' }} align="right">
                          {t('Subscribers')}
                        </TableCell>
                        <TableCell align="right">{t('Price')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.courses.map((course) => (
                        <TableRow key={course.id}>
                          <TableCell component="th" scope="row">
                            {course.name}
                          </TableCell>
                          <TableCell>{getCourseDate(course)}</TableCell>
                          <TableCell align="right">{course.subscriptionsNbre}</TableCell>
                          <TableCell align="right">{course.price}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    )
  }

  return (
    <div className="payment-container">
      <CardHeader title={t('Billings & Earnings')}>
        <Box alignItems={'center'} display={{ md: 'flex' }}>
          <StatBox
            icon="icon-business"
            iconBg="#3ac47d"
            title={t('Capital Gains')}
            count={`${stats?.collected || ''}`}
          />
          <StatBox
            icon="icon-business"
            iconBg="#f7b924"
            title={t('Pending')}
            count={`${stats?.pending || ''}`}
          />
        </Box>
      </CardHeader>
      <Box mt={5}>
        <TableContainer
          component={Paper}
          className={'table-container'}
          style={{ maxHeight: 800 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>{t('Reference')}</TableCell>
                <TableCell align="right">{t('Date')}</TableCell>
                <TableCell align="right">{t('Amount')}</TableCell>
                <TableCell align="right">{t('Status')}</TableCell>
                <TableCell align="right">{t('Invoice')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getRows().map((row) => (
                <Row key={row.name} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box width={1} mt={4}>
          <TablePagination
            page={page}
            totalPages={totalPages}
            setPage={(page) => setPage(page)}
          />
        </Box>
      </Box>
    </div>
  )
}
export default Payment
