import moment from 'moment'
import { dateFormat, timeFormat } from '../config'
import {
  categoriesUriPath,
  coursesUriPath,
  languagesUriPath,
  levelsUriPath
} from '../hooks/constants/api'

export function getCourseDate(course) {
  if (course.isCommunicationCourse) {
    return `${moment(course.startsAt).format(dateFormat)} ${
      course.sessions && course.sessions[0]
        ? `${moment(course.sessions[0].startsAt).format(timeFormat)} - ${moment(
            course.sessions[0] && course.sessions[0].endsAt
          ).format(timeFormat)}`
        : ''
    }`
  } else {
    return `${moment(course.startsAt).format(dateFormat)} To ${moment(
      course.endsAt
    ).format(dateFormat)}`
  }
}

export function showSessionDuration(timeStart, timeEnd) {
  return moment(moment(timeEnd)).diff(moment(timeStart), 'hours') < 1
    ? `${moment(moment(timeEnd)).diff(moment(timeStart), 'minutes').toFixed(2)} ${'min'}`
    : `${moment(moment(timeEnd))
        .diff(moment(timeStart), 'hours', true)
        .toFixed(2)} ${'h'}`
}

export function getSelectedLabels(data, selected, attr) {
  const selectedNames =
    data && data.filter((s) => selected.includes(s.id)).map((e) => e[attr])
  return selectedNames ? selectedNames.join(', ') : []
}

export function initialTime(hours, minutes) {
  let initialDate = new Date()
  initialDate.setHours(hours)
  initialDate.setMinutes(minutes)
  return initialDate
}

export function scrollToTop(top = 0) {
  window.scroll({
    top: top,
    left: 0,
    behavior: 'smooth'
  })
}

export function setUriPaths(payload) {
  if (payload.language?.id) {
    payload.language = `${languagesUriPath}/${payload.language.id}`
  }

  if (payload.level?.id) {
    payload.level = `${levelsUriPath}/${payload.level.id}`
  }

  if (payload.category?.id) {
    payload.category = `${categoriesUriPath}/${payload.category.id}`
  }

  if (payload.course) {
    payload.course = `${coursesUriPath}/${payload.course}`
  }

  return payload
}

export const getPageParams = (pagePayload, perPage) => {
  let filterURI = `?perPage=${perPage}`
  if (pagePayload.page) {
    filterURI += `&page=${pagePayload.page}`
  }
  return filterURI
}
