import React from 'react';
import { Typography } from "@material-ui/core";
import { CardHeader, CardMedia, CardContent, Collapse, Grid } from "@material-ui/core";
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { getCourseDate } from '../../helpers';

const CourseOverview = ({course}) => {

  const getSessionDate = () => {
    let dateSessionHtml = '';
    dateSessionHtml = getCourseDate(course);
    return (
      <Grid container className="mt-1" direction="row" alignItems="center">
        <EventAvailableIcon className="secondary mr-1"/> {dateSessionHtml}
      </Grid>
    )
  }

  return (
   <>
    <CardHeader
      title={course.name}
      subheader={getSessionDate(course)}
    />
    <CardMedia
      className="img-16_9"
      component={"iframe"}
      image={course.video}
      title={course.name}
    />
    <CardContent>
      <Typography variant="body2" color="textSecondary" component="p">
        <div dangerouslySetInnerHTML={{__html: course.shortDescription}}></div>
      </Typography>
    </CardContent>

    <Collapse in={true} timeout="auto" unmountOnExit>
      <CardContent>
        <Typography paragraph>
          <div dangerouslySetInnerHTML={{__html: course.description}}></div>
        </Typography>
      </CardContent>
    </Collapse>
   </>
  );
};

export default CourseOverview;
