import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Button, CircularProgress, Typography, Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Alert, AlertTitle } from '@material-ui/lab'
import {
  resetUserPassword,
  newUserPassword,
  useAuthDispatch,
  useAuthState,
  initErrors
} from '../../../hooks/context'
import Logo from '../../../components/Logo'

function ResetPassword(props) {
  const { t } = useTranslation('messages')
  const [state, setValues] = useState({
    email: '',
    pass: ''
  })
  const [token, setToken] = useState('')

  const dispatch = useAuthDispatch()
  const { loading, errorMessage, success } = useAuthState()

  useEffect(() => {
    const query = new URLSearchParams(props.location.search)
    setToken(query.get('token'))
  }, [props.location.search])

  useEffect(() => {
    initErrors(dispatch)
  }, [dispatch])

  const handleChange = (evt) => {
    setValues((prevProps) => ({
      ...prevProps,
      [evt.target.name]: evt.target.value
    }))
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()
    try {
      let payload = { ...state, token }
      token ? newUserPassword(dispatch, payload) : resetUserPassword(dispatch, payload)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="reset-form">
      <div className="container-flex reset-form-content">
        <Logo />
        <div className="inner-form">
          {success ? (
            <div className="full-width mb-2">
              <Alert severity="success" fullWitdth>
                {token ? (
                  <>
                    <AlertTitle>{t('Password Updated!')}</AlertTitle>
                    {t('password changed successfully')}
                    <Link to={{ pathname: '/login' }}>{t('login')}</Link>.
                  </>
                ) : (
                  <>
                    <AlertTitle>{t('Check your email')}</AlertTitle>
                    {t(
                      'We just sent an email to you with a link to reset your password!'
                    )}
                  </>
                )}
              </Alert>
            </div>
          ) : (
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" component="span">
                    <p className="mb-2 txt-center">
                      {token
                        ? t('Reset your password')
                        : t(
                            "Enter your email address and we'll send you a link to reset your password"
                          )}
                    </p>
                  </Typography>
                  {errorMessage ? (
                    <div className="full-width mb-2">
                      <Alert severity="error">{errorMessage}</Alert>
                    </div>
                  ) : null}
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {token ? (
                    <TextField
                      required
                      className="form-input"
                      type="password"
                      name="pass"
                      label="Password"
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                    />
                  ) : (
                    <TextField
                      required
                      className="form-input"
                      name="email"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <div className="mt-1">
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      disabled={loading}
                    >
                      {token ? t('Reset password') : t('Send Email')}
                      {loading && (
                        <CircularProgress size={24} className="ml-1" color="secondary" />
                      )}
                    </Button>
                    {token ? null : (
                      <div className="mt-1">
                        <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          color="primary"
                          href="/login"
                        >
                          {t('Cancel')}
                        </Button>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
