import { walletConstants } from "../constants/walletConstants";
import { ordersUrlPath, paymentUrlPath, paymentStatusUrlPath }  from "../constants/api";
import { transactionsPerPage } from "../../config/config";
import { publicApi } from "../../services/api";

export async function getTransactionsHistory(dispatch, userPayload = null) {
  let URIParams = '';
  if(userPayload && (userPayload.filter || userPayload.page) ) {
    URIParams = getFilterParams(userPayload);
  }
  try {
    dispatch({ type: walletConstants.REQUEST_TRANSACTION_HISTORY_GET });
    let response = await publicApi.get(`${ordersUrlPath}${URIParams}`);
    let data = await response.data;
    if (data) {
      dispatch({ type: walletConstants.GET_TRANSACTION_HISTORY_SUCCESS,
      payload: {
        data : data['hydra:member'],
        totalPages:
          data && data["hydra:totalItems"]
        ? Math.ceil(data["hydra:totalItems"] / transactionsPerPage)
        : 0,
      }
      });
      return data;
    }
    dispatch({ type: walletConstants.GET_TRANSACTION_HISTORY_ERROR, error: data});
    return;
  } catch (error) {
    dispatch({ type: walletConstants.GET_TRANSACTION_HISTORY_ERROR, error: error });
  }
}

export async function creditWallet(dispatch, walletPayload) {
  try {
    dispatch({ type: walletConstants.REQUEST_WALLET_CREDIT });
    let response = await publicApi.post(paymentUrlPath, JSON.stringify(walletPayload));
    let data = await response.data;
    if (data.orderId ) {
      window.location.href = data.formUrl;
      return;
    }
    dispatch({ type: walletConstants.CREDIT_WALLET_ERROR, error: data.errorMessage || data.message});
    return;
  } catch (error) {
    dispatch({ type: walletConstants.CREDIT_WALLET_ERROR, error: error });
  }
}

export async function updateCreditWallet(dispatch, walletPayload) {
  try {
    dispatch({ type: walletConstants.REQUEST_WALLET_PAYMENT_STATUS });
    let response = await publicApi.post(paymentStatusUrlPath, JSON.stringify(walletPayload));
    let data = await response.data;
    if (data) {
      dispatch({ type: walletConstants.PAYMENT_STATUS_WALLET_SUCCESS, payload: data });
      return;
    }
    dispatch({ type: walletConstants.PAYMENT_STATUS_WALLET_ERROR, error: data.message});
    return;
  } catch (error) {
    console.log(error)
    dispatch({ type: walletConstants.PAYMENT_STATUS_WALLET_ERROR, error: error });
  }
}

export async function initErrors(dispatch) {
  dispatch({ type: walletConstants.INIT_ERRORS });
}

const getFilterParams = (userPayload) => {
  let filterURI = `?perPage=${transactionsPerPage}`;
  if (userPayload.page) {
    filterURI += `&page=${userPayload.page}`;
  }
  return filterURI;
}
