import {
  AppBar,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Tab,
  Typography
} from '@material-ui/core'
import { Rating, TabContext, TabList } from '@material-ui/lab'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CommentsList from '../../components/CommentsList'
import LinearProgressWithLabel from '../../components/Utils/LinearProgressWithLabel'
import { apiMediaUrl } from '../../hooks/constants/api'

const CourseRating = ({
  myRating,
  rating,
  ratingStats,
  user,
  isSubscribedUser,
  loadCommentsPage,
  commentsPage,
  comments,
  handleModalReview,
  handleModalReport,
  trainerReviews
}) => {
  const { t } = useTranslation('common')
  const [tab, setTab] = useState('current-course')
  const reviews = () => {
    const n = 4
    let reviewsItems = []
    for (let i = n; i >= 0; i--) {
      var rate =
        ratingStats &&
        ratingStats.ratings_details &&
        ratingStats.ratings_details[`rated_${i + 1}`]
      reviewsItems.push(
        <LinearProgressWithLabel
          key={i}
          labelNumber={i + 1}
          value={(rate && rate.percent) || 0}
          color={'secondary'}
          number={(rate && rate.number) || 0}
        />
      )
    }
    return reviewsItems
  }

  const handleChangeTab = (event, newTab) => {
    setTab(newTab)
  }

  const loadMore = () => {
    loadCommentsPage(commentsPage + 1)
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    )
  }

  const commentsList = () => {
    return (
      <TabContext value={tab}>
        <AppBar className="tabs tabs__review no-shadow mt-1" position="static">
          <TabList onChange={handleChangeTab} color="secondary" centered>
            <Tab
              key={'current-course'}
              label={t('Of this course')}
              value={'current-course'}
            />
            <Tab key={'old-courses'} label={t('Other courses')} value={'old-courses'} />
          </TabList>
          <TabPanel value={tab} index={'current-course'}>
            <Box my={5}>
              <CommentsList comments={comments?.list} />
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={'old-courses'}>
            <Box my={5}>
              <CommentsList comments={trainerReviews} withCourse />
            </Box>
          </TabPanel>
        </AppBar>
      </TabContext>
    )
  }

  const handleModal = (open) => {
    handleModalReview(open)
  }

  const showModalReport = (open) => {
    handleModalReport(open)
  }

  const ReviewAction = () => {
    return (
      <Box display="flex" gridGap={4}>
        <Typography
          variant="body1"
          color="secondary"
          className="hover-underline"
          onClick={() => handleModal(true)}
        >
          {myRating?.rating ? t('Edit your review') : t('Leave a review')}
        </Typography>
        {isSubscribedUser && (
          <>
            <Typography className="secondary">/</Typography>
            <Typography
              variant="body1"
              color="secondary"
              className="hover-underline"
              onClick={() => showModalReport(true)}
            >
              {t('Report')}
            </Typography>
          </>
        )}
      </Box>
    )
  }

  return (
    <Grid container spacing={4}>
      {rating ? (
        <>
          <Grid item md={3} xs={12} alignItems="center" alignContent="center">
            <Box className="txt-center">
              <Typography variant="h1">{rating}</Typography>
              <div>
                <Rating value={rating} readOnly size="small" />
              </div>
              <Typography variant="body1" color="textSecondary">{`${
                (ratingStats && ratingStats.ratings_number) || 0
              } total`}</Typography>
            </Box>
          </Grid>
          <Grid item md={9} xs={12}>
            <div className="mb-2">
              {ratingStats && ratingStats.ratings_details ? reviews() : ''}
            </div>
            {myRating?.rating && (
              <div className="mb-2">
                <Grid container className="mt-1 mb-1" direction="row" alignItems="center">
                  <Avatar
                    className="avatar-small mr-1"
                    aria-label="avatar"
                    src={user.avatar && `${apiMediaUrl}${user.avatar.contentUrl}`}
                  />
                  <Typography
                    className="ml-1"
                    variant="body2"
                    color="textSecondary"
                    component="span"
                  >
                    {user.fullname}
                  </Typography>
                </Grid>
                <Box display="block">
                  <Rating value={myRating?.rating?.value} readOnly size="small" />
                  <Typography className="ml-1" variant="body2" color="textSecondary">
                    {t('posted')} {moment(myRating?.comment?.createdAt).fromNow()}
                  </Typography>
                  <Typography className="pt-1" variant="body2">
                    <div className="mb-2">{myRating?.comment?.value}</div>
                  </Typography>
                </Box>
              </div>
            )}
            <ReviewAction />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{t('Reviews')}</Typography>
            <div className="mt-2">{commentsList()}</div>
            {comments.totalPages && comments.totalPages > commentsPage ? (
              <Button
                className=""
                type="submit"
                size="large"
                variant="contained"
                color="secondary"
                fullWidth
                disabled={comments.loading}
                onClick={loadMore}
              >
                {' '}
                {t('Load more')}
                {comments.loading && (
                  <CircularProgress size={24} className="ml-1" color="secondary" />
                )}
              </Button>
            ) : (
              ''
            )}
          </Grid>
        </>
      ) : (
        <Box>
          <Typography variant="body1" color="textSecondary" className="mb-1">
            {t('No reviews for the moment.')}
          </Typography>
          <br />
          <ReviewAction />
        </Box>
      )}
    </Grid>
  )
}

export default CourseRating
