import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Avatar } from '@material-ui/core'
import { AvatarGroup } from '@material-ui/lab'
import { apiMediaUrl } from '../../../hooks/constants/api'
import MuiDialog from '../../../components/Utils/MuiDialog'
import MuiAvatar from '../MuiAvatar'

const MuiAvatarGroup = ({ avatars, limit, noModal }) => {
  const { t } = useTranslation(['messages', 'common'])
  const [open, setOpen] = React.useState(false)

  const openAllSubscribersModal = () => {
    setOpen(true)
  }

  const closeAllSubscribersModal = (open) => {
    setOpen(open)
  }

  const showAllSubscribersModal = () => {
    return (
      <MuiDialog
        open={open}
        setOpen={closeAllSubscribersModal}
        title={t('Subscribers')}
        content={
          avatars &&
          avatars.map((avatar, key) => (
            <MuiAvatar
              authorData={{ avatar: avatar.avatar?.contentUrl, fullname: avatar.name }}
            />
          ))
        }
        actions={
          <>
            <Button
              onClick={() => closeAllSubscribersModal(false)}
              color="primary"
              autoFocus
            >
              {t('Cancel')}
            </Button>
          </>
        }
      />
    )
  }

  return (
    <>
      {avatars?.length ? (
        <AvatarGroup max={limit} onClick={openAllSubscribersModal}>
          {avatars.map((avatar, key) => (
            <Avatar
              key={`avatar-${key}`}
              className="avatar-small mt-1"
              alt={avatar.name}
              src={`${apiMediaUrl}${avatar.avatar?.contentUrl}`}
            />
          ))}
        </AvatarGroup>
      ) : (
        ''
      )}
      {!noModal && showAllSubscribersModal()}
    </>
  )
}

export default MuiAvatarGroup
