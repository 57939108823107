import React from 'react';
import { Typography } from "@material-ui/core";

const ListItems = ({items, title }) => {
  return (
    items && items.length > 0 ? (
      <>
        <Typography variant="h3"> - {title}</Typography>
        <ul>
        {items.map((item, key) => (
          <li key={key}><Typography variant="subtitle1">{item}</Typography></li>
        ))}
        </ul>
      </>
  ) : ('')
  );
};

export default ListItems;
