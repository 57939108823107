import React, { useEffect } from 'react';
import Logo from '../../../components/Logo';
import Auth from '../../../components/auth/Auth';
import cover from "../../../assets/images/cover-login.jpg";
import { loginUser, useAuthDispatch, useAuthState, initErrors } from '../../../hooks/context';

function Login () {

  const dispatch = useAuthDispatch();
  const { loading, errorMessage } = useAuthState()

  useEffect(() => {
    initErrors(dispatch);
  }, [dispatch]);

  const handleSubmit = async (payload) => {
    try {
      loginUser(dispatch, payload);
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="container-flex auth-form">
      <div className="side-left col">
        <div className="logo">
          <Logo />
        </div>
        <Auth type="login" submit={handleSubmit} loading={loading} errorMessage={errorMessage}/>
      </div>
      <div className="side-right col">
        <div className="banner" style={{backgroundImage: `url(${cover})`}}></div>
      </div>
    </div>
  );
}

export default Login;
