import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Logo from '../../Logo';
import { Typography, Box, Container, Grid } from "@material-ui/core";
import CourseRequest from "../../../components/CourseRequest";
import LangSelectorList from '../../../components/Utils/LangSelector/LangSelectorList';
import { CourseRequestsProvider } from "../../../hooks/context/CourseRequestsContext";
import { UsersProvider } from "../../../hooks/context/UsersContext";
import { useAppSettingsState } from '../../../hooks/context/AppSettingsContext';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import './style.scss';

const Footer = ({requestCourses}) => {
  const { t } = useTranslation('common');
  const { categories } = useAppSettingsState();

  return (
    <>
    <div className="footer-floated-btn">
      {requestCourses && 
        <CourseRequestsProvider>
            <UsersProvider>
              <CourseRequest />
            </UsersProvider>
        </CourseRequestsProvider>
      }
    </div>
    <footer>
      <Container className="inner-footer" maxWidth={false}>
        <Grid container>
          <Grid item md={3} xs={12}>
            <Logo />
            <div className="bloc">
              <Typography variant="body2" component="p">
                <LocationOnIcon />47 Destiny Common, South Jolieview
              </Typography>
              <Typography variant="body2" component="p">
                <PhoneIcon />826-696-8370
              </Typography>
              <Typography variant="body2" component="p">
                <MailIcon />contact@yoodemy.com
              </Typography>
            </div>
          </Grid>
          <Grid item md={3} xs={12}>
            <div className="bloc">
              <Typography variant="h5">
                Explore
              </Typography>
              <Typography variant="body2" className="link">
                <Link to={{ pathname: "/courses"}} className="textSecondary">{t('Courses')}</Link>
                <Link to={{ pathname: "/Trainers"}} className="textSecondary">{t('Trainers')}</Link>
                <Link to={{ pathname: "/"}} className="textSecondary">{t('About us')}</Link>
                <Link to={{ pathname: "/tos" }} className="textSecondary">{t("Terms of Use")}</Link>
                <Link to={{ pathname: "/privacy" }} className="textSecondary">{t("Privacy Policy")}</Link>
              </Typography>
            </div>
          </Grid>
          <Grid item md={3} xs={12}>
            <div className="bloc">
              <Typography variant="h5">
                Categories
              </Typography>
              <Typography variant="body2" className="link">
                {categories && categories.slice(0,5).map((cat, key) => (
                  <Link
                    key={key}
                    to={{
                      pathname: '/courses/',
                      search: `?category=${cat.id}`,
                    }}
                    className="textSecondary">{cat.name}
                  </Link>
                ))}
              </Typography>
            </div>
          </Grid>
          <Grid item md={3} xs={12}>
            <div className="bloc">
              <Typography variant="h5">
                Language
              </Typography>
              <LangSelectorList />
            </div>
          </Grid>
        </Grid>
        <div item md={4} xs={12} className="footer-bot">
          <Container>
            <Box className="flex-col-mobile" display="flex" alignItems="center" justifyContent="center">
              <Typography variant="body2" component="span" className="-bold">
                © {`${new Date().getFullYear()} Yoodemy. ${t('All rights reserved.')}`}
              </Typography>
            </Box>
          </Container>
        </div>
      </Container>
    </footer>
    </>
  );
};

export default Footer;
